import React, { useEffect, useState } from 'react';
import '../../pages/CSS/ChangePassword.css';  // Ensure this path is correct for your project
import axios from 'axios';
import { BaseUrl, ShowMsg } from '../../MainComponent/mainfile';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import ChangePasswordImg from '../../../admin/assets/change-password.png' 
const Logo = () => {
    return (
        <div className='nav-logo-change'>
            <img
                width="378"
                height="116"
                src="https://oasisnotes.com/wp-content/uploads/2024/07/oasisLogo.png"
                className="custom-logo changepassword-logo"
                alt="Oasisnotes.com"
                decoding="async"
                srcSet="https://oasisnotes.com/wp-content/uploads/2024/07/oasisLogo.png 378w, https://oasisnotes.com/wp-content/uploads/2024/07/oasisLogo-300x92.png 300w"
                sizes="(max-width: 378px) 100vw, 378px"
            />
        </div>
    );
};

const ChangePassword = () => {
    const [token, setToken] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const newPassword = data.get('newPassword');
        const confirmPassword = data.get('confirmPassword');
        if (!newPassword?.trim() || !confirmPassword?.trim()) {
            ShowMsg("Error", "Username and Confirm Passwords fields cannot be empty", "danger");
            return;
          }
      if (newPassword?.trim() !== confirmPassword?.trim()) {
        ShowMsg(
          "Error",
          "Confirm password does not match the password",
          "danger"
        );
        return;
      }
        try {
            axios
                .post(`${BaseUrl}/admin/set-password`, {
                    token: token,
                    "password": newPassword?.trim(),
                    "confirm_password": confirmPassword?.trim(),
                })
                .then((res) => {
                    navigate('/')
                    ShowMsg("Success", res.data.message, "success");
                })
                .catch((err) => {
                    ShowMsg("Error", err?.response?.data?.message, "danger");
                });
        } catch (error) {
            ShowMsg("Error", error.msg, "error");
        }
    };

    return (
        <div className="change-password-container-main">
            <Logo />
            <Container className="container-box container-box-change-password">
                <Row className="align-items-center">
                    <Col sm="12" md="5">
                        <div className="change-password-image p-4 d-none d-md-block">
                            <img src={ChangePasswordImg} className='img-fluid' />
                        </div>
                    </Col>
                    <Col sm="12" md="6" className="offset-md-1">
                        <div className="change-password-container p-4">
                            <div className="change-password-card">
                                <h3 className='change-password-heading mb-3 mb-md-5 fw-bold'>Change Password</h3>
                                <form className="change-password-form" onSubmit={handleSubmit}>
                                    <div className="form-group form-group-change-password">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input type="password" id="newPassword" name="newPassword" required />
                                    </div>
                                    <div className="form-group form-group-change-password">
                                        <label htmlFor="confirmPassword">Confirm New Password</label>
                                        <input type="password" id="confirmPassword" name="confirmPassword" required />
                                    </div>
                                    <button type="submit" className="change-password-button mt-3">Change Password</button>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChangePassword;
