/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Layout/Outer/HOC";
import { getObjectUrlFromDownloadUrl, showNotification, UpdateProfile } from "../../Repository/Apis";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/authSlice";
import defaultProfile from '../../admin/assets/user.png'
const InputGroup = ({
  label,
  setValue,
  value,
  placeholder,
  type = "text",
  pattern,
}) => {
  return (
    <div className="Input_Group mb-3">
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        pattern={type === "tel" ? pattern : undefined}
      ></Form.Control>
    </div>
  );
};

const Profile = () => {
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middle, setMiddle] = useState("");
  const ProfileDetails = useSelector(userProfile);
  const [logoImage, setLogoImage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (ProfileDetails) {
      setEmail(ProfileDetails?.email);
      setMobileNumber(ProfileDetails?.mobileNumber);
      setAddress(ProfileDetails?.address);
      setFirstName(ProfileDetails?.firstName);
      setLastName(ProfileDetails?.lastName);
      setMiddle(ProfileDetails?.middle);
      setGender(ProfileDetails?.gender);
      setCompanyName(ProfileDetails?.companyName)
      setLogoImage(ProfileDetails?.logo)
    }
  }, [ProfileDetails]);


  const fd = new FormData();

  function appendIfPresent(formValue, value) {
    if (value) {
      fd.append(formValue, value);
    }
  }
  appendIfPresent("image", image);
  appendIfPresent("firstName", firstName);
  appendIfPresent("lastName", lastName);
  appendIfPresent("middleName", middle);
  appendIfPresent("gender", gender);
  appendIfPresent("mobileNumber", mobileNumber);
  appendIfPresent("email", email);
  appendIfPresent("address", address);
  appendIfPresent("logo", logoImage);
  appendIfPresent("companyName", companyName);

  const handleSubmit = async (e) => {
    let updateUrl 
    if(ProfileDetails?.userType==="Admin"){
      updateUrl= `/admin/updateProfile`
    }else if(ProfileDetails?.userType==="Employee"){
      updateUrl = `employee/updateProfile`
    }else if(ProfileDetails.userType==="Patient"){
      updateUrl= `Patient/updateProfile`
    }
    e.preventDefault();
    dispatch(
      UpdateProfile({
        payload: fd,
        setLoading,
        url: updateUrl,
        isAdmin :ProfileDetails?.userType==="Admin"
      })
    );
  };

  const selectImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({ message: "Please select a valid JPG or PNG image.", type: "default" });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({ message: "File size should be less than 2 MB.", type: "default" });
        return;
      }
      setImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
    }
  };
  const selectImageLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({ message: "Please select a valid JPG or PNG image.", type: "default" });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({ message: "File size should be less than 2 MB.", type: "default" });
        return;
      }
      setLogoImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImageUrl(imageUrl);
    }
  };

  const ChooseFile = () => {
    const target = document.getElementById("file");
    target.click();
  };
  const ChooseFileLogo = () => {
    const target = document.getElementById("logoFile");
    target.click();
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <div className="page-title-bar">
            <p className="heading">
              Profile
            </p>
          </div>
        </Col>
      </Row>
      <Card body className="mb-3">
        <Row>
          <Col xs={12} lg={4} xl={3}>
            <div className="profile-section">
              <div className="main">
                <div className="contain">
                  <Card body className="mb-3">
                    <img
                      src={imageUrl ? imageUrl : ProfileDetails?.profilePic ? getObjectUrlFromDownloadUrl(ProfileDetails?.profilePic) : defaultProfile}
                      alt=""
                      className="cursor-pointer"
                     
                    />
                    <h6 className="mt-3 mb-0 text-center fw-bold">
                      {" "}
                      {ProfileDetails?.firstName ?? ""}{" "}
                      {ProfileDetails?.middleName ?? ""}{" "}
                      {ProfileDetails?.lastName ?? ""}{" "}
                    </h6>
                    <div className="upload-profile">
                    <button  onClick={() => ChooseFile()} className="upload-btn"> Change Profile </button>
                    <div className="Input_Group">
                      <input
                        type={"file"}
                        className="d-none"
                        id="file"
                        onChange={(e) => selectImage(e)}
                      />
                    </div>
                    </div>
                  </Card>
                  <Card body className="mb-3 relative">
                    {ProfileDetails.userType === "Admin" && <img
                      src={tempImageUrl ? tempImageUrl : getObjectUrlFromDownloadUrl(ProfileDetails?.logo)}
                      alt=""
                      style={{ width: '200px', objectFit: 'contain', borderRadius: '0', background: 'white' }}
                     
                    />}
                    <h6 className="mt-3 mb-0 text-center fw-bold">{companyName}</h6>
                    <div className="upload-profile">
                    {ProfileDetails.userType === "Admin" && 
                       <button onClick={() => ChooseFileLogo()} className="upload-btn"> Change Logo </button>
                    }
                    {ProfileDetails.userType === "Admin" && <div className="Input_Group">
                      <input
                        type={"file"}
                        className="d-none"
                        id="logoFile"
                        accept=".jpg,.png"
                        onChange={(e) => selectImageLogo(e)}
                      />
                    </div>}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={8} xl={9}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} lg={4}>
                  <InputGroup
                    label={"First Name"}
                    setValue={setFirstName}
                    value={firstName}
                    type="text"
                    placeholder="First Name"
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <InputGroup
                    label={"Middle Name"}
                    setValue={setMiddle}
                    value={middle}
                    type="text"
                    placeholder="Middle Name"
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <InputGroup
                    label={"Last Name"}
                    setValue={setLastName}
                    value={lastName}
                    type="text"
                    placeholder="Last Name"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <InputGroup
                    label={"Gender"}
                    setValue={setGender}
                    value={gender}
                    type="text"
                    placeholder="Gender"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <InputGroup
                    label={"Mobile Number"}
                    setValue={setMobileNumber}
                    value={mobileNumber}
                    type="tel"
                    placeholder="Mobile Number"
                  // pattern={"[0-9]{10}"}
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <InputGroup
                    label={"Email Address"}
                    setValue={setEmail}
                    value={email}
                    type="email"
                    placeholder="email@gmail.com"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <InputGroup
                    label={"Address"}
                    setValue={setAddress}
                    value={address}
                    type="text"
                  />
                </Col>
                <Col xs={12} lg={12}>
                  {ProfileDetails.userType === "Admin" && <InputGroup
                    label={"Company Name"}
                    setValue={setCompanyName}
                    value={companyName}
                    type="text"
                  />}
                </Col>
              </Row>
              <Row className="text-center">
                <Col xs={12} lg={12}>
                  <button className="employee_create_btn mt-3">
                    {" "}
                    {loading ? <ClipLoader color="#fff" /> : "Update"}{" "}
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default HOC({ Wcomponenet: Profile });
