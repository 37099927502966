/** @format */

import { useState } from "react";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { BorderlessSelect, handleKeyDownResidentStrength } from "../../../Helper/Makers";
import NavWrapper from "../../../Helper/NavWrapper";
import HOC from "../../../Layout/EmployeeBar/HOC";
import { createApi } from "../../../Repository/Apis";
import EmployeeComponent from "../../../components/Search/EmployeeComponent";
import CreatableSelect from 'react-select/creatable';

const Forms2023 = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Employee’s Arizona Withholding Election");
  const [file, setFile] = useState("");
  const [employeeId, setEmployeeId] = useState('');
  const [employeeData, setEmployeeData] = useState('');
  const [employeeName, setEmployeeName] = useState('');

  const fd = new FormData();
  fd.append("type", type);
  fd.append("image", file);

  const submitHandler = (e) => {
    e.preventDefault();
    createApi({
      url: `admin/createForms2023/${employeeId}`,
      setLoading,
      payload: fd,
    });
  };

  const options = [
    {
      value: "2023 Forms",
      label: "2023 Forms",
    },
  ];

  return (
    <>
      <NavWrapper
        isArrow={true}
        title={"Employee’s Arizona Withholding Election"}
      />
      <Container>
        <Form onSubmit={submitHandler}>
          <Row className="mb-2">
            <Col xs={12}>
            <EmployeeComponent
                MainPatientId={setEmployeeId}
                setWholeData={setEmployeeData}
                MainResidentName={setEmployeeName}
              />
              
            </Col>
          </Row>
          <Card body className="mb-3">
            <Row>
              <Col xs={12}>
                <Form.Label className="fw-bold">
                  ▶ Go to{" "}
                  <a
                    href="https://azdor.gov/business/withholding-tax"
                    target="_blank"
                  >
                    azdor.gov/business/withholding-tax
                  </a>{" "}
                  for instructions and the latest information.
                </Form.Label>
              </Col>
            </Row>
          </Card>
          <Card body className="mb-3">
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Choose file</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Type</Form.Label>
                
                     <CreatableSelect
                    isMulti={false}
                    options={options}
                    onChange={(v) => setType(v)}
                    value={type}
                    components={{ DropdownIndicator: null }}
                    onKeyDown={(event) =>
                      handleKeyDownResidentStrength(event, options, setType, type)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card>
          <Row className="text-center">
            <Col xs={12}>
              <button className="employee_create_btn" type="submit">
                {loading ? <ClipLoader color="#fff" /> : "SUBMIT"}
              </button>
            </Col>
          </Row>
        </Form>
      </Container>

    </>
  );
};
export default HOC({ Wcomponenet: Forms2023, isNavbar: false });
