/** @format */

import React, { useState, useEffect } from "react";
import { createFirebaseDocument, getApi, getObjectUrlFromDownloadUrl } from "../../Repository/Apis";
import { Dropdown, Modal, Offcanvas } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { setDocumentID } from "../../store/chatSlice";
import { CreateGroup } from "../../Canvas/Canvases";
import { fetchPaitentName } from "../../utils/utils";
import { defaultUserImg } from "../../assets";
import '../Chat/Chat.css'
const CreateChat = ({ handleClose, show,setUserType,chatType }) => {
  const [allEmployees, setAllEmployess] = useState({});
  const [allPatients, setAllPatiens] = useState({});
  const [limit, setLimit] = useState(25);
  const [patientLimit, setPatientLimit] = useState(25);
  const [patientLoading, setPatientLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const ProfileDetails = useSelector(userProfile);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      getApi({
        url: `admin/getUserForChat?userType=Employee&limit=${limit}`,
        setResponse: setAllEmployess,
        setLoading,
      });
    }
  }, [limit, show]);

  useEffect(() => {
    if (show) {
      getApi({
        url: `admin/getUserForChat?userType=Patient&limit=${patientLimit}`,
        setResponse: setAllPatiens,
        setLoading: setPatientLoading,
      });
    }
  }, [patientLimit, show]);

  const hasMore =
    allEmployees?.data?.totalDocs > allEmployees?.data?.docs?.length;
  const hasMorePatient =
    allPatients?.data?.totalDocs > allPatients?.data?.docs?.length;

  const customDebounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const loadMore = customDebounce(() => {
    if (
      limit === allEmployees?.data?.totalDocs ||
      limit < allEmployees?.data?.totalDocs
    ) {
      setLimit(limit + 25);
    }
  }, 500);

  const loadMorePatient = customDebounce(() => {
    if (
      patientLimit === allPatients?.data?.totalDocs ||
      patientLimit < allPatients?.data?.totalDocs
    ) {
      setPatientLimit(limit + 25);
    }
  }, 500);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: loading,
  });
  const [patientRef] = useInfiniteScroll({
    loading: patientLoading,
    hasNextPage: hasMorePatient,
    onLoadMore: loadMorePatient,
    disabled: patientLoading,
  });

  const createDocument = ({ collectionName, navigationLink, recipientObj }) => {
    const payload = {
      recipient: recipientObj,
      sender: ProfileDetails,
      text: [],
    };

    dispatch(
      createFirebaseDocument({
        payload,
        collectionName,
        // navigate,
        navigationLink,
        handleClose,
        recipientName: recipientObj,
      })
    );
  };

  const handleClick = (link) => {
    navigate(link);
    dispatch(setDocumentID(""));
    handleClose();
  };

  const openGroup = () => {
    handleClose();
    setOpen(true);
    setUserType('Group')
  };

  const filterdEmployess = allEmployees?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );
  return (
    <>
      {<CreateGroup show={open} handleClose={() => setOpen(false)} />}
      <Modal show={show} onHide={handleClose} placement="end">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Create New Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="eclipse-dropdown">
            {chatType === "Group"&&<button onClick={() => openGroup()}>Create New Group</button>}
            {/* <button onClick={() => handleClick("/group-chat")}>
              {" "}
              All Groups
            </button>
            <button onClick={() => handleClick("/chat")}>
              {" "}
              Employee Conversations
            </button>
            <button onClick={() => handleClick("/patientChat")}>
              {" "}
              Patient Discussions
            </button> */}
          </div>

          {chatType =="Employee"&& filterdEmployess?.length > 0 && (
            <>
              <div className="go-to-chat">
                <h5 className="fw-bold ">
                  Employee's ( {filterdEmployess?.length} ){" "}
                </h5>
              </div>

              <div className="create-new-chat-room">
                {filterdEmployess?.map((i, index) => (
                  <div
                    className="select-employee"
                    onClick={() =>{
                      createDocument({
                        recipientObj: i,
                        collectionName: "EmployeeChat",
                        navigationLink: "/chat",
                      });
                      setUserType('Employee')}
                    }
                    key={`user${index}`}
                  >
                    <img
                      src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                      className="original-img"
                      alt=""
                    />

                    <div className="content">
                      <p className="heading text-start">{fetchPaitentName(i)} </p>
                      <p className="faded"> {i.mobileNumber} </p>
                      <p className="faded">{i.email} </p>
                    </div>
                  </div>
                ))}
                {loading && (
                  <div style={{ display: "block", margin: "auto" }}>
                    <ClipLoader />
                  </div>
                )}
                <div ref={sentryRef}></div>
              </div>
            </>
          )}

          {chatType =="Patient"&&  allPatients?.data?.docs?.length > 0 && (
            <>
              <div className="go-to-chat">
                <h5 className="fw-bold ">
                  Patients ( {allPatients?.data?.docs?.length} ){" "}
                </h5>
              </div>

              <div className="create-new-chat-room">
                {allPatients?.data?.docs?.map((i, index) => (
                  <div
                    className="select-employee"
                    onClick={() =>{
                      createDocument({
                        recipientObj: i,
                        collectionName: "PatientChat",
                        navigationLink: "/chat",
                      });
                      setUserType('Patient')
                    }
                    }
                    key={`patient${index}`}
                  >
                    <img
                      src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                      className="original-img"
                      alt=""
                    />

                    <div className="content">
                      <p className="heading text-start">{fetchPaitentName(i)} </p>
                      <p className="faded"> {i.mobileNumber} </p>
                      <p className="faded">{i.email} </p>
                    </div>
                  </div>
                ))}
                {patientLoading && (
                  <div style={{ display: "block", margin: "auto" }}>
                    <ClipLoader />
                  </div>
                )}
                <div ref={patientRef}></div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateChat;
