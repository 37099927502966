/** @format */
import { useEffect, useState } from "react";
import { Button, Container, Modal, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import { createApi, getObjectUrlFromDownloadUrl, LogOutHandler, uploadDocument } from "../Repository/Apis";
import {
  AdminSidebar,
  FilesNames,
  Innernav,
  MainSidebar,
  mobileSidebarOptions,
  ResidentSidebar,
} from "../assets/Constant/Constant";
import { ClipLoader } from "react-spinners";
import { DateFormtter } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../store/authSlice";
import CreateChat from "../Pages/Chat/CreateChat";
import CustomSelect from "../components/selector/CustomSelect";
import { employeeSidebarNav,adminEmployeeSidebarNav } from '../assets/Constant/Constant'
export function OuterSidebar({ show, handleClose }) { //"../../assets/Constant/Constant";
  const location = useLocation();
  const ProfileDetails = useSelector(userProfile);
  const navigate = useNavigate();
    const [navItem, setNavItem] = useState([]);

  const [isChat, setIsChat] = useState(false);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
   
  useEffect(() => {
    if(ProfileDetails?.userType==="Admin"){
      setNavItem(AdminSidebar)
    }else if(ProfileDetails?.userType==="Employee"){
      setNavItem(MainSidebar)
    }else if(ProfileDetails?.userType==="Patient"){
      setNavItem(ResidentSidebar)
    }

  },[ProfileDetails])
  
  return (
    <>
      <CreateChat show={isChat} handleClose={() => setIsChat(false)} />

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="Outer_sider_canvas_container"
      >
        <Offcanvas.Body className="Outer_sidebar_Body">
          <div className="close_btn">
            <i
              className="fa-solid fa-circle-xmark"
              onClick={() => handleClose()}
            ></i>
          </div>
          <div className="Logo_img">
            {ProfileDetails?.adminId?.logo ? (
              <img
                style={{
                  maxWidth: "155px",
                  maxHeight: "55px",
                  display: "block",
                  margin: "auto",
                }}
                src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo)}
                alt=""
              />
            ) : (
              <img
                style={{
                  maxWidth: "155px",
                  maxHeight: "55px",
                  display: "block",
                  margin: "auto",
                }}
                src="/logo.png"
                alt=""
              />
            )}
          </div>

          <div className="outer-siderbar-nav">
            <ul className="Nav ">
              {navItem?.map((nav, index) => (
                <li
                  key={`nav${index}`}
                  onClick={() => navigate(nav.link)}
                  className={location.pathname === nav.link && "active"}
                >
                  
                  {typeof nav.icon === 'string' ? (
                      <img src={getObjectUrlFromDownloadUrl(nav.img)} alt="" />
                    ) : (
                      <nav.icon color={"white"} size={40}/>
                    )}
                  <span>{nav.name}</span>
                </li>
              ))}
            </ul>
            <span className="cursor-pointer d-block my-2 text-white">
              <img
                src="/Navbar/chat.png"
                className="navbar-notify-image d-block m-auto relative -left-[7px]"
                onClick={() => {
                  handleClose();
                  setIsChat(true);
                }}
                alt=''
              />
              Chat
            </span>
            <span onClick={logOut} className="cursor-pointer d-block text-white">
              <BiLogOutCircle
                style={{
                  width: "100%",
                  fontSize: "25px",
                  marginTop: "2rem",
                  color: "white",
                }}
                className="text-xl mr-3"
              />
              Logout
            </span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const SignatureModal = (props) => {
  const today = new Date();
  const stringDate = today.toISOString();
  const [time, setTime] = useState("");
  const [name, setName] = useState(props?.value);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date();
      const hours = today.getHours();
      const min = today.getMinutes();
      const seconds = today.getSeconds();
      setTime(`${hours}:${min}:${seconds}`);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function valueSetter() {
    props?.setValue(name);
    props?.setTime(time);
    props?.setDate(stringDate?.split("T")?.[0]);
    props.onHide();
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="sing_modla"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="singature_modal">
          <h6> Digitally Sign by {props?.value} </h6>
          <h6>
            {" "}
            Date : {stringDate && DateFormtter(stringDate)} Time : {time}{" "}
          </h6>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter your Lorem Ipsum"
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={valueSetter}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DocumentUploader = (props) => {
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [arr, setArr] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [patientId, setPaitentId] = useState("");

  const removeFile = (index) => {
    const filterThis = arr?.filter((_, i) => index !== i);
    setArr(filterThis);
  };

  // File Upload
  const filePayload = new FormData();
  filePayload.append("file", file);
  filePayload.append("type", fileType);
  const uploadFiles = (e) => {
    e.preventDefault();
    uploadDocument({
      payload: filePayload,
      setArr,
      setLoading: setUploading,
      patitentId:props.patitentId
    });
  };
  

  const payload = {
    patientId: props?.patitentId ? props?.patitentId : patientId,
    data: arr,
  };

  const submitHandler = () => {
    const additionalFunctions = [props.onHide, props?.fetchDocument];
    createApi({
      url: `employee/createUploadDocument1`,
      payload,
      successMsg: "Uploaded !",
      setLoading: setSubmitLoading,
      additionalFunctions,
    });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="file-upload-modal">
        <Container className="full-width-container">
          <form onSubmit={uploadFiles}>
            <div className="close-header">
              <h5>File Upload </h5>
              <i
                className="fa-solid fa-xmark"
                onClick={() => props.onHide()}
              ></i>
            </div>

            <div className="wrapper">
              {/* <PatientComponent
                className={"search-field mb-3 absolute-container"}
                MainPatientId={setPaitentId}
              /> */}

              <div className="flexbox">
                {/* <div className="items"> */}
                  {/* <p className="head">Actions</p> */}
                  {/* <button type="submit">
                    {uploading ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      "Add Additional files"
                    )}
                  </button> */}
                {/* </div> */}
                <div className="items">
                  <p className="head">File Type</p>
                  {/* <select onChange={(e) => setFileType(e.target.value)}>
                    <option value=""> Select Prefrence </option>
                    {FilesNames?.map((i) => (
                      <option value={i} key={i}>
                        {" "}
                        {i}{" "}
                      </option>
                    ))}
                  </select> */}
                  <CustomSelect options={FilesNames}
                      onChange={(value) => setFileType(value)} />
                </div>
                <div className="items">
                  <p className="head">File Name</p>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="items">
                    <p className="head">Upload File</p>
                    <div>
                      <button className="upload_files" type="submit">
                        {uploading ? (
                          <ClipLoader color="#fff" />
                        ) : (
                          "Upload File"
                        )}
                      </button>
                    </div>
                  </div>
              </div>

              <table className="colored_table mt-3">
                <thead>
                  <tr>
                    <th className="text-start">Type</th>
                    <th className="text-start">File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {arr?.map((i, index) => (
                    <tr key={index}>
                      <td className="text-start"> {i.type} </td>
                      <td className="text-start">
                        <a href={i.document} target="_blank" rel="noreferrer">
                          View File
                        </a>
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-trash-can cursor-pointer"
                          onClick={() => removeFile(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <div className="btn-container">
                <button
                  className="upload_files"
                  onClick={() => submitHandler()}
                  type="button"
                >
                  {submitLoading ? <ClipLoader color="#fff" /> : "Upload Files"}
                </button>
              </div> */}
            </div>
          </form>
        </Container>
        
      </Modal.Body>
    </Modal>
  );
};

export function InnerSidebars({ show, handleClose, type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const ProfileDetails = useSelector(userProfile);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  const [currentNavList ,setCurrentNavList] = useState([])

  useEffect(() => {
    if(ProfileDetails?.userType==="Admin"){
      setCurrentNavList(AdminSidebar)
    }else if(ProfileDetails?.userType==="Employee"){
      if(location?.pathname=='/employment')
        setCurrentNavList(employeeSidebarNav)
      else
      setCurrentNavList(Innernav)
    }else if(ProfileDetails?.userType==="Patient"){
      setCurrentNavList(ResidentSidebar)
    }
  },[ProfileDetails?.userType])
  
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="Outer_sider_canvas_container"
      >
        <Offcanvas.Body className="Outer_sidebar_Body">
          <div className="close_btn">
            <i
              className="fa-solid fa-circle-xmark"
              onClick={() => handleClose()}
            ></i>
          </div>
          <figure className="flex  flex-col items-center">
            <span
              style={{
                textAlign: "center",
                lineHeight: ".1rem",
              }}
              className="font-bold text-white text-2xl"
            >
              {ProfileDetails?.adminId?.logo ? (
                <img
                  style={{
                    maxWidth: "155px",
                    maxHeight: "55px",
                    display: "block",
                    margin: "auto",
                  }}
                  src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo)}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    maxWidth: "155px",
                    maxHeight: "55px",
                    display: "block",
                    margin: "auto",
                  }}
                  src="/logo.png"
                  alt=""
                />
              )}
              <div className="text-sm mt-3" style={{ lineHeight: ".6rem" }}>
                <span>
                  <Button
                    style={{
                      backgroundColor: "#1A9FB2",
                      border: "2px solid white",
                      padding: "0 1.3rem",
                      color: "white",
                    }}
                    onClick={() => logOut()}
                  >
                    Sign Out
                  </Button>
                </span>
                <br />
              </div>
            </span>
          </figure>
          <nav className="py-6">
            {currentNavList.map((nav) =>(
                  <Link
                    to={nav.link}
                    key={nav.name}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: ".8rem",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <span
                        style={{
                          fontSize: ".5rem",
                          maxWidth: "30px",
                        }}
                      >
                        {/* <img src={nav.icon} alt="" /> */}
                        {typeof nav.icon === 'string' ? (
                      <img src={nav.icon} alt="" />
                    ) : (
                      <nav.icon />
                    )}
                      </span>
                      <span
                        style={{
                          color: "white",
                          borderBottom: "none",
                          whiteSpace: "normal",
                          fontSize: "0.7rem",
                          textDecoration: "none",
                        }}
                      >
                        {nav.name}
                      </span>
                    </div>
                  </Link>
                )
              )
            }
              </nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const DocumentUploaderEmployee = (props) => {
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [arr, setArr] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const removeFile = (index) => {
    const filterThis = arr?.filter((_, i) => index !== i);
    setArr(filterThis);
  };

  // File Upload
  const filePayload = new FormData();
  filePayload.append("file", file);
  filePayload.append("type", fileType);
  const uploadFiles = (e) => {
    e.preventDefault();
    uploadDocument({
      payload: filePayload,
      setArr,
      setLoading: setUploading,
    });
  };

  const payload = {
    data: arr,
  };

  const submitHandler = () => {
    const additionalFunctions = [props.onHide];
    createApi({
      url: `employee/createUploadDocumentForEmployee`,
      payload,
      successMsg: "Uploaded !",
      setLoading: setSubmitLoading,
      additionalFunctions,
    });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="file-upload-modal">
        <Container className="full-width-container">
          <form onSubmit={uploadFiles}>
            <div className="close-header">
              <h5>File Upload </h5>
              <i
                className="fa-solid fa-xmark"
                onClick={() => props.onHide()}
              ></i>
            </div>

            <div className="wrapper">
              <div className="flexbox">
                <div className="items">
                  <p className="head">Actions</p>
                  <button type="submit">
                    {uploading ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      "Add Additional files"
                    )}
                  </button>
                </div>
                <div className="items">
                  <p className="head">File Type</p>
                  {/* <select onChange={(e) => setFileType(e.target.value)}>
                    <option value=""> Select Prefrence </option>
                    {FilesNames?.map((i) => (
                      <option value={i} key={i}>
                        {" "}
                        {i}{" "}
                      </option>
                    ))}
                  </select> */}
                  <CustomSelect options={FilesNames}
                      onChange={(value) => setFileType(value)} />
                </div>
                <div className="items">
                  <p className="head">File Name</p>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>

              <table className="colored_table mt-3">
                <thead>
                  <tr>
                    <th className="text-start">Type</th>
                    <th className="text-start">File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {arr?.map((i, index) => (
                    <tr key={index}>
                      <td className="text-start"> {i.type} </td>
                      <td className="text-start">
                        <a href={i.document} target="_blank" rel="noreferrer">
                          View File
                        </a>
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-trash-can cursor-pointer"
                          onClick={() => removeFile(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="btn-container">
                <button
                  className="upload_files"
                  onClick={() => submitHandler()}
                  type="button"
                >
                  {submitLoading ? <ClipLoader color="#fff" /> : "Upload Files"}
                </button>
              </div>
            </div>
          </form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
