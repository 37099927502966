/** @format */

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HOC from "../../../Layout/Inner/HOC";
import { getApi, getObjectUrlFromDownloadUrl, removeApi } from "../../../Repository/Apis";
import CreateNav from "../../../Helper/CreateNav";
import { DateFormtter, fetchPaitentName } from "../../../utils/utils";
import {
  DropList,
  TableLayout,
} from "../../../components/HelpingComponents";
import { useSelector } from "react-redux";
import { userProfile } from "../../../store/authSlice";

const AllLRC = () => {
  const profile = useSelector(userProfile);
  const isAdmin = profile.userType === 'Admin';
  const [logs, setLogs] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllLogs = () => {
    getApi({
      url: "employee/getAllLRC",
      setLoading,
      setResponse: setLogs,
    });
  };

  useEffect(() => {
    getAllLogs();
  }, []);

  const deleteHandler = async (id) => {
    const additionalFunctions = [getAllLogs];
    removeApi({
      url: `employee/deleteLrc1031AById/${id}`,
      successMsg: "Removed",
      additionalFunctions,
    });
  };

  const dataList =
   logs &&  logs?.data?.length > 0
      ? logs?.data
          ?.reverse()
          ?.filter((log) => log?.employeeId)
          ?.map((log) => [
            DateFormtter(log?.updatedAt),
            fetchPaitentName(log?.employeeId),
            "",
            <DropList
              viewLink={getObjectUrlFromDownloadUrl(log?.document)}
              editLink={`/dashboard/lrc-1031a/${log?.employeeId?._id}`}
              deleteLink={() => deleteHandler(log?._id)}
              canEdit={true}
            />,
          ])
      : [];

  const combinedDataSource = [...dataList];

  return (
    <>
      <CreateNav title={"LRC-1031"}  link={"/dashboard/create-lrc-1031a"} isAuthorizedToCreate= {isAdmin ? false : true }/>

      <Container className="full-width-container">
        <TableLayout
          thead={[
            "Date",
            "Employee Name",
            "",
            "",
          ]}
          tbody={combinedDataSource}
          loading={loading}
        />
      </Container>
    </>
  );
};

export default HOC(AllLRC);
