import React, { lazy } from 'react';
import AlllAppendix from '../Pages/EmploymentInformation/Appendix/AlllAppendix';
import All2023 from '../Pages/EmploymentInformation/2023Forms/All2023';
import AllLRC from '../Pages/EmploymentInformation/LRC-1031/AllLRC';
import AllFW4 from '../Pages/EmploymentInformation/FW-4/AllFW4';
import AllFW9 from '../Pages/EmploymentInformation/FW9/AllFW9';
import AllI9 from '../Pages/EmploymentInformation/I-9/AllI9';
import Create2023 from '../Pages/EmploymentInformation/2023Forms/Create2023';
import CreateFw4 from '../Pages/EmploymentInformation/FW-4/CreateFw4';
import CreateFw9 from '../Pages/EmploymentInformation/FW9/CreateFw9';
import CreateLrc from '../Pages/EmploymentInformation/LRC-1031/CreateLrc';
import CreateI9 from '../Pages/EmploymentInformation/I-9/CreateI9';
import InitialAssismentList from '../Pages/Intake/InitalAssisment/InitialAssismentList';
// Lazy load the components

// Admin Routing
const Contacts = lazy(()=>import ('../admin/pages/Dashboard/Contacts'))
const Logs = lazy(()=>import ('../admin/pages/Dashboard/Logs'))
const Tracking = lazy(()=>import ('../admin/pages/Dashboard/Tracking'))
const ReferenceCheckAdmin = lazy(()=>import ('../admin/pages/Dashboard/ReferenceCheck'))
const PTORequest = lazy(()=>import ('../admin/pages/Dashboard/PTORequest'))
const EmployeePerformanceAdmin = lazy(()=>import ('../admin/pages/Dashboard/EmployeePerformance'))
const CalenderSchedule = lazy(()=>import ('../admin/pages/Dashboard/Calandar'))
const ActivityScheduleAdmin = lazy(()=>import ('../admin/pages/Dashboard/ActivitySchedule'))
const Notifications = lazy(()=>import ('../admin/pages/Dashboard/Notifications'))
const JobDescriptionAdmin = lazy(()=>import ('../admin/pages/Dashboard/JobDescription'))
const OfferLater = lazy(()=>import ('../admin/pages/Dashboard/OfferLater'))
const PatientVitals = lazy(()=>import ('../admin/pages/Dashboard/PatientVitals'))
const NotesLibrary2 = lazy(()=>import ('../admin/pages/Dashboard/NotesLibrary2'))
const PatinentTracking = lazy(()=>import ('../admin/pages/Dashboard/PatinentTracking'))
const HomePage1 = lazy(()=>import ('../admin/pages/HomePage1'))
const StaffSchedule2 = lazy(()=>import ('../admin/pages/Dashboard/StaffSchedule2'))
const DashboardAdmin = lazy(() => import("../admin/pages/Dashboard"));
const AdminLogo = lazy(() => import("../admin/pages/Dashboard/AdminLogo"));
const EmployeeList = lazy(() => import("../admin/pages/Dashboard/EmployeeList"));
const EmployeeData = lazy(() => import("../admin/pages/Dashboard/EmployeeData"));

// Employee Routing
const CreateRefusal = lazy(() => import("../Pages/Medication/Refusal/CreateRefusal"));
const AssignedPatient = lazy(() => import("../Pages/Assigned_Patient/Assigned_Patient"));
const Profile = lazy(() => import("../Pages/Profile/Profile"));
const Personal = lazy(() => import("../Pages/EmploymentInformation/Peronal_Information/Personal"));
const OfferLetter = lazy(() => import("../Pages/EmploymentInformation/OfferLetter/OfferLetter"));
const SignOfferLetter = lazy(() => import("../Pages/EmploymentInformation/OfferLetter/SignOfferLetter"));
const SignReferenceCheck = lazy(() => import("../Pages/EmploymentInformation/ReferenceCheck/EditRefrenceCheck"));
const Appendix = lazy(() => import("../Pages/EmploymentInformation/Appendix/Appendix"));
const Forms2023 = lazy(() => import("../Pages/EmploymentInformation/2023Forms/2023Forms"));
const ReferenceCheck = lazy(() => import("../Pages/EmploymentInformation/ReferenceCheck/ReferenceCheck"));
const LRC1031 = lazy(() => import("../Pages/EmploymentInformation/LRC-1031/LRC-1031"));
const APS = lazy(() => import("../Pages/EmploymentInformation/APS/APS"));
const JobDescription = lazy(() => import("../Pages/EmploymentInformation/JobDescription/JobDescription"));
const FW4Form = lazy(() => import("../Pages/EmploymentInformation/FW-4/FW-4"));
const I9Form = lazy(() => import("../Pages/EmploymentInformation/I-9/I9Form"));
const FW9 = lazy(() => import("../Pages/EmploymentInformation/FW9/FW9"));
const GroupNotes = lazy(() => import("../Pages/Group Notes/GroupNotes"));
const Traning = lazy(() => import("../Pages/Training/Traning"));
const OnSite = lazy(() => import("../Pages/Training/OnSite/OnSite"));
const Skills = lazy(() => import("../Pages/Training/SkillTraining/Skills"));
const TimeOfRequest = lazy(() => import("../Pages/Time Off Request/TimeOfRequest"));
const Medications = lazy(() => import("../Pages/Medication/Medications"));
const MedicationCount = lazy(() => import("../Pages/Medication/Count/MedicationCount"));
const InformedConsent = lazy(() => import("../Pages/Medication/InformedConsent/InformedConsent"));
const PRNform = lazy(() => import("../Pages/Medication/MedicationLog/PRNform"));
const PatientTracking = lazy(() => import("../Pages/Patient Tacking/PatientTracking"));
const PatientChart = lazy(() => import("../Pages/PatientChart/PatientMenu/PatientChartWrapper"));
const Discharge = lazy(() => import("../Pages/PatientChart/Discharge/Discharge"));
const EmployeePerformance = lazy(() => import("../Pages/Employee Performance/EmployeePerformance"));
const EmployeeTracking = lazy(() => import("../Pages/Employee Tracking/EmployeeTracking"));
const TimeSheet = lazy(() => import("../Pages/Time Sheet/TimeSheet"));
const TimeSheetView = lazy(() => import("../Pages/Time Sheet/TimeSheetView"));
const PatientList = lazy(() => import("../Pages/Patients/PatientListWrapper"));
const Vitals2 = lazy(() => import("../Pages/PatientVitals/Vitals2"));
const OnSite2 = lazy(() => import("../Pages/Training/OnSite/OnSite2"));
const Skills2 = lazy(() => import("../Pages/Training/SkillTraining/skills2"));
const Reconciliations2 = lazy(() => import("../Pages/Medication/Reconciliation/Reconsilations2"));
const MedicautionCount2 = lazy(() => import("../Pages/Medication/Count/MedicautionCount2"));
const InformedConsent2 = lazy(() => import("../Pages/Medication/InformedConsent/InformedConsent2"));
const PRNform2 = lazy(() => import("../Pages/Medication/MedicationLog/PRNform2"));
const EditProgressNote = lazy(() => import("../Pages/PatientChart/Progress/EditProgressNote"));
const UpdateIncidentReport = lazy(() => import("../Pages/PatientChart/IncidentReport/UpdateIncidentReport"));
const UpdateDischarge = lazy(() => import("../Pages/PatientChart/Discharge/UpdateDischarge"));
const UpdatePrn = lazy(() => import("../Pages/Medication/MedicationLog/UpdatePrn"));
const UpdateInform = lazy(() => import("../Pages/Medication/InformedConsent/UpdateInform"));
const UpdateCount = lazy(() => import("../Pages/Medication/Count/UpdateCount"));
const ViewCount = lazy(() => import("../Pages/Medication/Count/ViewCount"));
const ViewInform = lazy(() => import("../Pages/Medication/InformedConsent/ViewInform"));
const Employment = lazy(() => import("../Pages/EmploymentInformation/EmplymentMenu/Employment"));
const ProgressNote2 = lazy(() => import("../Pages/PatientChart/Progress/ProgressNote2"));
const ProgressNote = lazy(() => import("../Pages/PatientChart/Progress/ProgressNote"));
const PersonalInfo = lazy(() => import("../Pages/EmploymentInformation/Peronal_Information/PersonalInfo"));
const ViewInfo = lazy(() => import("../Pages/EmploymentInformation/Peronal_Information/ViewInfo"));
const AllAps = lazy(() => import("../Pages/EmploymentInformation/APS/AllAps"));
const EditAPS = lazy(() => import("../Pages/EmploymentInformation/APS/EditAPS"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const SearchPage = lazy(() => import("../Pages/Search/SearchPage"));
const BasicInformation = lazy(() => import("../Pages/Employment Application/BasicInformation"));
const EducationalBackground = lazy(() => import("../Pages/Employment Application/EducationalBackground"));
const EmploymentHistory = lazy(() => import("../Pages/Employment Application/EmploymentHistory"));
const OtherInformation = lazy(() => import("../Pages/Employment Application/OtherInformation"));
const Acknowledgement = lazy(() => import("../Pages/Employment Application/Acknowledgement"));
const Vitals = lazy(() => import("../Pages/PatientVitals/Vitals"));
const EditOnSite = lazy(() => import("../Pages/Training/OnSite/EditOnSite"));
const ServiceLog = lazy(() => import("../Pages/Training/ServiceLog/ServiceLog"));
const CreateServiceLog = lazy(() => import("../Pages/Training/ServiceLog/CreateServiceLog"));
const EditServiceLog = lazy(() => import("../Pages/Training/ServiceLog/EditServiceLog"));
const NotFound = lazy(() => import("../Pages/404/NotFound"));
const BookAppointment = lazy(() => import("../Pages/Patients/BookAppointment"));
const ViewSite = lazy(() => import("../Pages/Training/OnSite/ViewSite"));
const ViewServiceLog = lazy(() => import("../Pages/Training/ServiceLog/ViewServiceLog"));
const EditSkill = lazy(() => import("../Pages/Training/SkillTraining/EditSkill"));
const ViewSkills = lazy(() => import("../Pages/Training/SkillTraining/ViewSkills"));
const GetTimeOfRequest = lazy(() => import("../Pages/Time Off Request/GetTimeOfRequest"));
const ViewTimeOfRequest = lazy(() => import("../Pages/Time Off Request/ViewTimeOfRequest"));
const Schedule = lazy(() => import("../Pages/Time Sheet/Schedule"));
const ViewAps = lazy(() => import("../Pages/EmploymentInformation/APS/ViewAps"));
const CreateDischarge = lazy(() => import("../Pages/PatientChart/Discharge/CreateDischarge"));
const ViewDischarge = lazy(() => import("../Pages/PatientChart/Discharge/ViewDischarge"));
const DTF = lazy(() => import("../Pages/PatientChart/Activities/DTF"));
const CreateDTF = lazy(() => import("../Pages/PatientChart/Activities/CreateDTF"));
const UpdateDTF = lazy(() => import("../Pages/PatientChart/Activities/UpdateDTF"));
const ViewDTF = lazy(() => import("../Pages/PatientChart/Activities/ViewDTF"));
const FinancialRecord = lazy(() => import("../Pages/PatientChart/FinancialRecord/FinancialRecord"));
const CreateRecord = lazy(() => import("../Pages/PatientChart/FinancialRecord/CreateRecord"));
const EditFinancialRecord = lazy(() => import("../Pages/PatientChart/FinancialRecord/EditFinancialRecord"));
const ViewFinancialRecord = lazy(() => import("../Pages/PatientChart/FinancialRecord/ViewFinancialRecord"));
const StaffNotes = lazy(() => import("../Pages/PatientChart/StaffNote/StaffNotes"));
const CreateStaffNote = lazy(() => import("../Pages/PatientChart/StaffNote/CreateStaffNote"));
const UpdateStaffNote = lazy(() => import("../Pages/PatientChart/StaffNote/UpdateStaffNote"));
const ViewStaffNote = lazy(() => import("../Pages/PatientChart/StaffNote/ViewStaffNote"));
const IncidentReport = lazy(() => import("../Pages/PatientChart/IncidentReport/IncidentReport"));
const CreateIncident = lazy(() => import("../Pages/PatientChart/IncidentReport/CreateIncident"));
const ContactNote = lazy(() => import("../Pages/PatientChart/ContactNote/ContactNote"));
const CreateContactNote = lazy(() => import("../Pages/PatientChart/ContactNote/CreateContactNote"));
const UpdateContactNote = lazy(() => import("../Pages/PatientChart/ContactNote/UpdateContactNote"));
const ViewContactNote = lazy(() => import("../Pages/PatientChart/ContactNote/ViewContactNote"));
const ViewIncidentReport = lazy(() => import("../Pages/PatientChart/IncidentReport/ViewIncidentReport"));
const Authorization = lazy(() => import("../Pages/PatientChart/Authorization/Authorization"));
const CreateAuthorization = lazy(() => import("../Pages/PatientChart/Authorization/CreateAuthorization"));
const UpdateAuthorization = lazy(() => import("../Pages/PatientChart/Authorization/UpdateAuthorization"));
const ViewAuthorization = lazy(() => import("../Pages/PatientChart/Authorization/ViewAuthorization"));
const TherapyNote = lazy(() => import("../Pages/TherapyNotes/Therapy/TherapyNote"));
const CreateTherapyNote = lazy(() => import("../Pages/TherapyNotes/Therapy/CreateTherapyNote"));
const MilegaLog = lazy(() => import("../Pages/TherapyNotes/MilegaLog/MilegaLog"));
const CreateMilegaLog = lazy(() => import("../Pages/TherapyNotes/MilegaLog/CreateMilegaLog"));
const UpdateMilegaLog = lazy(() => import("../Pages/TherapyNotes/MilegaLog/UpdateMilegaLog"));
const ViewMilega = lazy(() => import("../Pages/TherapyNotes/MilegaLog/ViewMilega"));
const Manaul = lazy(() => import("../Pages/TherapyNotes/Manual/Manaul"));
const Mars = lazy(() => import("../Pages/Medication/Mars/Mars"));
const Reconciliation = lazy(() => import("../Pages/Medication/Reconciliation/Reconciliation"));
const UpdateRecociliation = lazy(() => import("../Pages/Medication/Reconciliation/UpdateRecociliation"));
const ViewRecociliation = lazy(() => import("../Pages/Medication/Reconciliation/ViewRecociliation"));
const ViewPrn = lazy(() => import("../Pages/Medication/MedicationLog/ViewPrn"));
const ScheduleGroup = lazy(() => import("../Pages/Time Sheet/ScheduleGroup"));
// const Login = lazy(() => import("../Pages/Login/Login"));
const ViewProgreeNote = lazy(() => import("../Pages/PatientChart/Progress/ViewProgreeNote"));
const CreateMentalStatus = lazy(() => import("../Pages/Medication/MentalStatus/CreateMentalStatus"));
const MentalStatus = lazy(() => import("../Pages/Medication/MentalStatus/MentalStatus"));
const UpdateMentalStatus = lazy(() => import("../Pages/Medication/MentalStatus/UpdateMentalStatus"));
const ViewMentalStatus = lazy(() => import("../Pages/Medication/MentalStatus/ViewMentalStatus"));
const Refusal = lazy(() => import("../Pages/Medication/Refusal/Refusal"));
const UpdateRefusal = lazy(() => import("../Pages/Medication/Refusal/UpdateRefusal"));
const ViewRefusal = lazy(() => import("../Pages/Medication/Refusal/ViewRefusal"));
const CreateTrackingLog = lazy(() => import("../Pages/Medication/TrackingLog/CreateTrackingLog"));
const TrackingLog = lazy(() => import("../Pages/Medication/TrackingLog/TrackingLog"));
const UpdateTracking = lazy(() => import("../Pages/Medication/TrackingLog/UpdateTracking"));
const ViewTackingLog = lazy(() => import("../Pages/Medication/TrackingLog/ViewTackingLog"));
const FaceSheet = lazy(() => import("../Pages/Intake/FaceSheet/FaceSheet"));
const FaceSheetList = lazy(() => import("../Pages/Intake/FaceSheet/FaceSheetList"));
const NursingAssisment = lazy(() => import("../Pages/Intake/NursingAssisment/NursingAssisment"));
const NursingAssismentList = lazy(() => import("../Pages/Intake/NursingAssisment/NursingAssismentList"));
const ResidentIntake = lazy(() => import("../Pages/Intake/ResidentIntake/ResidentIntake"));
const ResidentIntakeList = lazy(() => import("../Pages/Intake/ResidentIntake/ResidentIntakeList"));
const TreatmentPlan = lazy(() => import("../Pages/Intake/TreatmentPlan/TreatmentPlan"));
const TreatmentPlanList = lazy(() => import("../Pages/Intake/TreatmentPlan/TreatmentPlanList"));
const SafetyPlan = lazy(() => import("../Pages/Intake/SafetyPlan/SafetyPlan"));
const SafetyPlanList = lazy(() => import("../Pages/Intake/SafetyPlan/SafetyPlanList"));


const Intake = lazy(() => import("../Pages/Intake/Intake"));
const InitialAssisment = lazy(() => import("../Pages/Intake/InitalAssisment/InitialAssisment"));
const All = lazy(() => import("../Pages/EmploymentInformation/All"));
const ViewFaceSheet = lazy(() => import("../Pages/Intake/FaceSheet/ViewFaceSheet"));
const Chat = lazy(() => import("../Pages/Chat/Chat"));
const PatientChat = lazy(() => import("../Pages/Chat/PatientChat"));
const ViewRefrenceCheck = lazy(() => import("../Pages/EmploymentInformation/ReferenceCheck/ViewRefrenceCheck"));
const EditRefrenceCheck = lazy(() => import("../Pages/EmploymentInformation/ReferenceCheck/EditRefrenceCheck"));
const GroupChat = lazy(() => import("../Pages/Chat/GroupChat/GroupChat"));
const EditTimeOfRequest = lazy(() => import("../Pages/Time Off Request/EditTimeOfRequest"));
const InfectionControl = lazy(() => import("../Pages/InfectionControl/InfectionControl"));
const CreateAssistanceMed = lazy(() => import("../Pages/AssistanceMed/CreateAssistanceMed"));
const GetInfectionControl = lazy(() => import("../Pages/InfectionControl/GetInfectionControl"));
const ViewInfectionControl = lazy(() => import("../Pages/InfectionControl/ViewInfectionControl"));
const EditInfectionControl = lazy(() => import("../Pages/InfectionControl/EditInfectionControl"));
const AssistanceMed = lazy(() => import("../Pages/AssistanceMed/AssistanceMed"));
const ViewAssistanceMed = lazy(() => import("../Pages/AssistanceMed/ViewAssistanceMed"));
const EditAssistanceMed = lazy(() => import("../Pages/AssistanceMed/EditAssistanceMed"));
const CreateFallPrevention = lazy(() => import("../Pages/FallPrevention/CreateFallPrevention"));
const FallPrevention = lazy(() => import("../Pages/FallPrevention/FallPrevention"));
const ViewFallPrevention = lazy(() => import("../Pages/FallPrevention/ViewFallPrevention"));
const EditFallPrevention = lazy(() => import("../Pages/FallPrevention/EditFallPrevention"));
const CreateTubercluosis = lazy(() => import("../Pages/Tuberculosis/CreateTubercluosis"));
const Tubercluosis = lazy(() => import("../Pages/Tuberculosis/Tubercluosis"));
const ViewTubercluosis = lazy(() => import("../Pages/Tuberculosis/ViewTubercluosis"));
const EditTubercluosis = lazy(() => import("../Pages/Tuberculosis/EditTubercluosis"));
const ActivitySchedule = lazy(() => import("../Pages/Time Sheet/ActivitySchedule"));
const ViewTherapy = lazy(() => import("../Pages/TherapyNotes/Therapy/ViewTherapy"));
const TherapyLog = lazy(() => import("../Pages/TherapyNotes/Therapy/TherapyLog"));
const UpdateTherapyLog = lazy(() => import("../Pages/TherapyNotes/Therapy/UpdateTherapyLog"));
const SignAppendix = lazy(() => import("../Pages/EmploymentInformation/Appendix/SignAppendix"));
const ViewAppendix = lazy(() => import("../Pages/EmploymentInformation/Appendix/ViewAppendix"));
const SignPersonal = lazy(() => import("../Pages/EmploymentInformation/Peronal_Information/SignPersonal"));
const EmployeePerformanceById = lazy(() => import("../Pages/Employee Performance/EmployeePerformanceById"));
const SignJobDescription = lazy(() => import("../Pages/EmploymentInformation/JobDescription/SignJobDescription"));
const EmployeeMedication = lazy(()=>import('../Pages/Medication/MedicationEmployee/Employee_Medication'))
const EmployeeTimeSheet = lazy(()=>import('../Pages/Time Sheet/TimeSheetList'))
const ClinicalOversight = lazy(()=>import("../Pages/ClinicalOversight/ClinicalOversight"))
const Receipts = lazy(()=>import("../Pages/Receipts/Receipts"))
const SpecialNote = lazy(()=>import("../Pages/SpecialNotes/SpecialNotes"))
const BaseClinicalOversight = lazy(() => import("../Pages/ClinicalOversight/BaseClinicalOversight"))

// Resident Routing

// const Home = lazy(() => import("./Pages/Home/Home"));
const Appointments = lazy(() => import("../resident/Components/Appointments/Appointments"));
const IntakeResident = lazy(() => import("../resident/Components/Intake/Intake"));
const InitialAssessment = lazy(() =>import("../resident/Components/Forms/Initial-Assessment"));
const ViewInitialAssessment = lazy(() =>import("../resident/Components/Forms/ViewInitialAssessment"));
const SafetyPlanResident = lazy(() => import("../resident/Components/Forms/SafetyPlan"));
const ViewSafetyPlan = lazy(() => import("../resident/Components/Forms/ViewSafetyPlan"));
const NursingAssessment = lazy(() =>import("../resident/Components/Forms/Nursing-Assessment"));
const ViewNursingAssessment = lazy(() =>import("../resident/Components/Forms/ViewNursingAssessment"));
const ResidentIntakes = lazy(() =>import("../resident/Components/Forms/ResidentIntakes"));
const ViewResidentIntakes = lazy(() =>import("../resident/Components/Forms/ViewResidentIntakes"));
const TreatmentplanUpdate = lazy(() =>import("../resident/Components/Forms/TreatmentPlan_update"));
const ViewTreatmentPlan = lazy(() =>import("../resident/Components/Forms/ViewTreatmentPlan"));
const FaceSheetResident = lazy(() => import("../resident/Components/Forms/FaceSheet"));
const InitialAssessmentDraft = lazy(() =>import("../resident/Components/Forms/Initial-AssessmentDraft"));
const FaceSheetDraft = lazy(() => import("../resident/Components/Forms/FaceSheetDraft"));
const SafetyPlanDraft = lazy(() =>import("../resident/Components/Forms/SafetyPlanDraft"));
const NursingAssessmentDarft = lazy(() =>import("../resident/Components/Forms/Nursing-AssessmentDraft"));
const TreatmentplanUpdateDraft = lazy(() =>import("../resident/Components/Forms/TreatmentPlan_updateDarft"));
const ResidentIntakesDraft = lazy(() =>import("../resident/Components/Forms/ResidentIntakesDraft"));
const FileUpload = lazy(() => import("../resident/Components/Appointments/FileUpload"));
const AppointmentScheduling = lazy(() =>import("../resident/Components/Appointment Scheduling/AppointmentScheduling"));
const BookAppointmentResident = lazy(() =>import("../resident/Components/Forms/BookAppointment"));
const ManageAppointments = lazy(() =>import("../resident/Components/Forms/ManageAppointments"));
const CancelAppointment = lazy(() =>import("../resident/Components/Forms/CancelAppointment"));
const AppointmentHistory = lazy(() =>import("../resident/Components/Forms/AppointmentHistory"));
const ChatResident = lazy(() => import("../resident/chat/Chat"));
const ProgressChartResident = lazy(()=>import('../resident/Components/ProgressChart/ProgressChartResident'))
// const MedicationResident = lazy(()=>import('../resident/Components/Medication/MedicationResident'))
// const UpdateProfile = lazy(() => import("../resident/Components/Forms/UpdateProfile"));
// const SaveAsDraft = lazy(() => import("./Components/Intake/SaveAsDraft"));
const ViewClinicalOversight = lazy(()=>import('../Pages/ClinicalOversight/ViewClinicalOversight'))
 export const adminRoutes = [
  { path: '/adminPage', element: <AdminLogo /> },
  { path: '/dashboard/contacts', element: <Contacts /> },
  { path: '/dashboard/tracking', element: <Tracking /> },
  { path: '/dashboard/logs', element: <Logs /> },
  { path: '/dashboard/pto', element: <PTORequest /> },
  { path: '/dashboard/staff-schedule', element: <StaffSchedule2 /> },
  { path: '/dashboard/activity-schedule', element: <ActivityScheduleAdmin /> },
  { path: '/dashboard/employee-performance', element: <EmployeePerformanceAdmin /> },
  { path: '/dashboard/notes', element: <NotesLibrary2 /> },
  { path: '/dashboard/notifications', element: <Notifications /> },
  { path: '/dashboard/calender-schedule', element: <CalenderSchedule /> },
  { path: '/dashboard/job-description', element: <JobDescriptionAdmin /> },
  { path: '/dashboard/offer-letter', element: <OfferLater /> },
  { path: '/dashboard/patient-vitals', element: <PatientVitals /> },
  { path: '/dashboard/patient-tracking', element: <PatinentTracking /> },
  { path: '/dashboard/homepage', element: <HomePage1 /> },
  { path: '/dashboard/reference-check', element: <ReferenceCheckAdmin /> },
  { path: '/employment-admin', element: <Employment /> },
  { path: '/employee-list', element: <EmployeeList /> },
  { path: '/dashboard/employee-data/:id', element: <EmployeeData /> },
  { path: '/dashboard/personal-information/:employeeId', element: <Personal /> },
  { path: '/dashboard/employee-tracking/:employeeId', element: <EmployeeTracking /> },
  { path: '/dashboard/sign-offer-letter-form/:employeeId', element: <SignOfferLetter /> },
  { path: '/dashboard/sign-personal/:employeeId', element: <SignPersonal /> },
  { path: '/dashboard/employee-information/:employeeId', element: <PersonalInfo /> },
  { path: '/dashboard/view-employee-information/:employeeId', element: <ViewInfo /> },
  { path: '/dashboard/view-appendix/:employeeId', element: <ViewAppendix /> },
  { path: '/dashboard/edit-appendix/:employeeId', element: <SignAppendix /> },
  { path: '/dashboard/create-forms-2023', element: <Create2023 /> },
  { path: '/dashboard/forms-2023/:employeeId', element: <Forms2023 /> },
  { path: '/dashboard/all-forms-2023', element: <All2023 /> },
  { path: '/dashboard/lrc-1031a/:employeeId', element: <LRC1031 /> },
  { path: '/dashboard/all-lrc-1031a/', element: <AllLRC /> },
  { path: '/dashboard/create-lrc-1031a', element: <CreateLrc /> },
  { path: '/dashboard/sign-job-description/:employeeId', element: <SignJobDescription /> },
  { path: '/dashboard/fw4/:employeeId', element: <FW4Form /> },
  { path: '/dashboard/create-fw4', element: <CreateFw4 /> },
  { path: '/dashboard/all-fw4', element: <AllFW4 /> },
  { path: '/dashboard/edit-aps/:employeeId', element: <EditAPS /> },
  { path: '/dashboard/all-fw9', element: <AllFW9 /> },
  { path: '/dashboard/create-fw9', element: <CreateFw9 /> },
  { path: '/dashboard/all-i9', element: <AllI9 /> },
  { path: '/dashboard/create-i9', element: <CreateI9 /> },
  { path: '/dashboard/view-aps/:employeeId', element: <ViewAps /> },
  { path: '/dashboard/fw9/:employeeId', element: <FW9 /> },
  { path: '/dashboard/i-9/:employeeId', element: <I9Form /> },
  { path: '/dashboard/all-forms/:employeeId', element: <All /> },
  { path: '/dashboard/on-site/:employeeId', element: <OnSite /> },
  { path: '/dashboard/get-infection-control/:employeeId', element: <GetInfectionControl /> },
  { path: '/dashboard/job-description/:employeeId', element: <JobDescription /> },
  { path: '/dashboard/view-site/:employeeId', element: <ViewSite /> },
  { path: '/dashboard/edit-site/:employeeId', element: <EditOnSite /> },
  { path: '/dashboard/infection-control/:employeeId', element: <ViewInfectionControl /> },
  { path: '/dashboard/edit-infection-control/:employeeId', element: <EditInfectionControl /> },
  { path: '/dashboard/view-assistance-med/:employeeId', element: <ViewAssistanceMed /> },
  { path: '/dashboard/edit-assistance-med/:employeeId', element: <EditAssistanceMed /> },
  { path: '/dashboard/fall-prevention/:employeeId', element: <ViewFallPrevention /> },
  { path: '/dashboard/edit-fall-prevention/:employeeId', element: <EditFallPrevention /> },
  { path: '/dashboard/tubercluosis/:employeeId', element: <ViewTubercluosis /> },
  { path: '/dashboard/edit-tubercluosis/:employeeId', element: <EditTubercluosis /> },
  { path: '/dashboard/time-sheet/:employeeId', element: <TimeSheetView /> },
  { path: '/dashboard/view-time-of-request/:employeeId', element: <ViewTimeOfRequest /> },
  { path: '/dashboard/edit-time-off-request/:employeeId', element: <EditTimeOfRequest /> },
  { path: '/dashboard/employee-performance/:employeeId', element: <EmployeePerformanceById /> },
  { path: '/dashboard/view-refrence-check/:employeeId', element: <ViewRefrenceCheck /> },
  { path: '/dashboard/edit-refrence-check/:id', element: <EditRefrenceCheck /> },
  { path: '/dashboard/view-service-log/:employeeId', element: <ViewServiceLog /> },
  { path: '/dashboard/edit-service-log/:employeeId', element: <EditServiceLog /> },
  { path: '/dashboard/view-site-training/:employeeId', element: <ViewSkills /> },
  { path: '/dashboard/edit-skill-training/:employeeId', element: <EditSkill /> },
  { path: '/dashboard/all-appendix', element: <AlllAppendix /> },
];

export const employeeRoutes = [
  { path: '/home', element: <Dashboard /> },
  { path: '/create-refusal', element: <CreateRefusal /> },
  { path: '/employment', element: <Employment /> },
  { path: '/offer-letter-form', element: <OfferLetter /> },
  // { path: '/profile', element: <Profile /> },
  { path: '/sign-offer-letter-form/:id', element: <SignOfferLetter /> },
  { path: '/appendix', element: <Appendix /> },
  { path: '/sign-appendix/:id', element: <SignAppendix /> },
  { path: '/view-appendix', element: <ViewAppendix /> },
  { path: '/forms-2023', element: <Forms2023 /> },
  { path: '/lrc-1031a', element: <LRC1031 /> },
  { path: '/aps-consent-form', element: <APS /> },
  { path: '/job-description', element: <JobDescription /> },
  { path: '/sign-job-description/:id', element: <SignJobDescription /> },
  { path: '/fw4', element: <FW4Form /> },
  { path: '/i-9', element: <I9Form /> },
  { path: '/fw9', element: <FW9 /> },
  { path: '/progree-note/:id', element: <EditProgressNote /> },
  { path: '/update-count/:id', element: <UpdateCount /> },
  { path: '/view-count/:id', element: <ViewCount /> },
  { path: '/view-inform/:id', element: <ViewInform /> },
  { path: '/personal-information', element: <Personal /> },
  { path: '/create-personal-information', element: <Personal /> },
  { path: '/sign-personal/:id', element: <SignPersonal /> },
  { path: '/employee/patient-chart/progress', element: <ProgressNote /> },
  { path: '/view-progress-note/:id', element: <ViewProgreeNote /> },
  { path: '/create-progress-note', element: <ProgressNote2 /> },
  { path: '/employee-information', element: <PersonalInfo /> },
  { path: '/view-employee-information', element: <ViewInfo /> },
  { path: '/view-employee-information/:id', element: <ViewInfo /> },
  { path: '/all-aps', element: <AllAps /> },
  { path: '/edit-aps/:id', element: <EditAPS /> },
  { path: '/view-aps/:id', element: <ViewAps /> },
  { path: '/search-list/:id', element: <SearchPage /> },
  { path: '/basic-information', element: <BasicInformation /> },
  { path: '/educational-background', element: <EducationalBackground /> },
  { path: '/employement-history', element: <EmploymentHistory /> },
  { path: '/other-information', element: <OtherInformation /> },
  { path: '/acknowledgement', element: <Acknowledgement /> },
  { path: '/group-notes', element: <GroupNotes /> },
  { path: '/employee/training', element: <Traning /> },
  { path: '/employee-tracking', element: <EmployeeTracking /> },
  { path: '/employee/patient-chart', element: <PatientChart /> },
  { path: '/vitals', element: <Vitals /> },
  { path: '/create-vital', element: <Vitals2 /> },
  { path: '/patient-tracking', element: <PatientTracking /> },
  { path: '/medications', element: <Medications /> },
  { path: '/on-site', element: <OnSite /> },
  { path: '/create-on-site-facility', element: <OnSite2 /> },
  { path: '/edit-on-site/:id', element: <EditOnSite /> },
  { path: '/view-site/:id', element: <ViewSite /> },
  { path: '/service-log', element: <ServiceLog /> },
  { path: '/create-service-log', element: <CreateServiceLog /> },
  { path: '/edit-service-log/:id', element: <EditServiceLog /> },
  { path: '/view-service-log/:id', element: <ViewServiceLog /> },
  { path: '/patient-list', element: <PatientList /> },
  { path: '/book-appointment/:id', element: <BookAppointment /> },
  { path: '/skills-knowledge-training', element: <Skills /> },
  { path: '/create-skill-training', element: <Skills2 /> },
  { path: '/edit-skill-training/:id', element: <EditSkill /> },
  { path: '/view-site-training/:id', element: <ViewSkills /> },
  { path: '/create-time-of-request', element: <TimeOfRequest /> },
  { path: '/get-time-of-request', element: <GetTimeOfRequest /> },
  { path: '/view-time-of-request/:id', element: <ViewTimeOfRequest /> },
  { path: '/edit-time-off-request/:id', element: <EditTimeOfRequest /> },
  { path: '/schedule', element: <Schedule /> },
  { path: '/time-sheet', element: <TimeSheet /> },
  { path: '/time-sheet/:id', element: <TimeSheetView /> },
  { path: '/timesheet-list', element: <EmployeeTimeSheet /> },
  { path: '/employee-performance', element: <EmployeePerformance /> },
  { path: '/employee-performance/:id', element: <EmployeePerformanceById /> },
  { path: '/create-discharge-summary', element: <CreateDischarge /> },
  { path: '/discharge-summary', element: <Discharge /> },
  { path: '/update-discharge/:id', element: <UpdateDischarge /> },
  { path: '/view-discharge/:id', element: <ViewDischarge /> },
  { path: '/DTF', element: <DTF /> },
  { path: '/create-dtf', element: <CreateDTF /> },
  { path: '/update-dtf/:id', element: <UpdateDTF /> },
  { path: '/view-dtf/:id', element: <ViewDTF /> },
  { path: '/financial-record', element: <FinancialRecord /> },
  { path: '/create-record', element: <CreateRecord /> },
  { path: '/edit-record/:id', element: <EditFinancialRecord /> },
  { path: '/view-record/:id', element: <ViewFinancialRecord /> },
  { path: '/staff-note', element: <StaffNotes /> },
  { path: '/create-staff-note', element: <CreateStaffNote /> },
  { path: '/update-staff-note/:id', element: <UpdateStaffNote /> },
  { path: '/view-staff-note/:id', element: <ViewStaffNote /> },
  { path: '/incident-report', element: <IncidentReport /> },
  { path: '/update-incident/:id', element: <UpdateIncidentReport /> },
  { path: '/create-incident-report', element: <CreateIncident /> },
  { path: '/view-incident-report/:id', element: <ViewIncidentReport /> },
  { path: '/contact-note', element: <ContactNote /> },
  { path: '/create-contact-note', element: <CreateContactNote /> },
  { path: '/update-contact-note/:id', element: <UpdateContactNote /> },
  { path: '/view-contact-note/:id', element: <ViewContactNote /> },
  { path: '/authorization', element: <Authorization /> },
  { path: '/create-authorization', element: <CreateAuthorization /> },
  { path: '/update-authorization/:id', element: <UpdateAuthorization /> },
  { path: '/view-authorization/:id', element: <ViewAuthorization /> },
  { path: '/therapy-notes', element: <TherapyNote /> },
  { path: '/therapy-log', element: <TherapyLog /> },
  { path: '/update-therapy-log/:id', element: <UpdateTherapyLog /> },
  { path: '/view-therapy-log/:id', element: <ViewTherapy /> },
  { path: '/create-therapy-note', element: <CreateTherapyNote /> },
  { path: '/milega-log', element: <MilegaLog /> },
  { path: '/create-milega-log', element: <CreateMilegaLog /> },
  { path: '/update-milega-log/:id', element: <UpdateMilegaLog /> },
  { path: '/view-milega-log/:id', element: <ViewMilega /> },
  { path: '/manual-theraphy', element: <Manaul /> },
  { path: '/mars', element: <Mars /> },
  { path: '/mars/:id', element: <Mars /> },
  { path: '/reconciliation', element: <Reconciliation /> },
  { path: '/create-reconciliation', element: <Reconciliations2 /> },
  { path: '/update-reconciliation/:id', element: <UpdateRecociliation /> },
  { path: '/view-reconciliation/:id', element: <ViewRecociliation /> },
  { path: '/employee/medications/medication-count', element: <MedicationCount /> },
  { path: '/create-medication-count', element: <MedicautionCount2 /> },
  { path: '/employee/medications/informed-consent', element: <InformedConsent /> },
  { path: '/create-informed-consent', element: <InformedConsent2 /> },
  { path: '/update-informed/:id', element: <UpdateInform /> },
  { path: '/employee/medications/prn-form', element: <PRNform /> },
  { path: '/create-prn-log', element: <PRNform2 /> },
  { path: '/view-prn/:id', element: <ViewPrn /> },
  { path: '/update-prn/:id', element: <UpdatePrn /> },
  { path: '/scheduleGroup', element: <ScheduleGroup /> },
  { path: '/create-mental-status', element: <CreateMentalStatus /> },
  { path: '/mental-status', element: <MentalStatus /> },
  { path: '/update-mental-status/:id', element: <UpdateMentalStatus /> },
  { path: '/view-mental-status/:id', element: <ViewMentalStatus /> },
  { path: '/create-refusal', element: <CreateRefusal /> },
  { path: '/refusal', element: <Refusal /> },
  { path: '/update-refusal/:id', element: <UpdateRefusal /> },
  { path: '/view-refusal/:id', element: <ViewRefusal /> },
  { path: '/create-tracking-log', element: <CreateTrackingLog /> },
  { path: '/tracking-log', element: <TrackingLog /> },
  { path: '/update-tracking-log/:id', element: <UpdateTracking /> },
  { path: '/view-tracking-log/:id', element: <ViewTackingLog /> },
  { path: '/edit-faceSheet/:id', element: <FaceSheet /> },
  { path: '/faceSheet', element: <FaceSheet /> },
  { path: '/faceSheet-list', element: <FaceSheetList /> },
  { path: '/view-faceSheet/:id', element: <ViewFaceSheet /> },
  { path: '/view-nursing-assessment/:id', element: <ViewNursingAssessment /> },
  { path: '/nursing-assessment', element: <NursingAssisment /> },
  { path: '/nursing-assessment-list', element: <NursingAssismentList /> },
  { path: '/edit-nursing-assessment/:id', element: <NursingAssisment /> },
  { path: '/view-resident-intake/:id', element: <ViewResidentIntakes /> },
  { path: '/resident-intake', element: <ResidentIntake /> },
  { path: '/resident-intake-list', element: <ResidentIntakeList /> },
  { path: '/edit-resident-intake/:id', element: <ResidentIntake /> },
  { path: '/view-treatment-plan/:id', element: <ViewTreatmentPlan /> },
  { path: '/treatment-plan', element: <TreatmentPlan /> },
  { path: '/treatment-plan-list', element: <TreatmentPlanList /> },
  { path: '/edit-treatment-plan/:id', element: <TreatmentPlan /> },
  { path: '/view-safety-plan/:id', element: <ViewSafetyPlan /> },
  { path: '/safety-plan', element: <SafetyPlan /> },
  { path: '/safety-plan-list', element: <SafetyPlanList /> },
  { path: '/edit-safety-plan/:id', element: <SafetyPlan /> },
  { path: '/intake/:id', element: <Intake /> },
  { path: '/view-initial-assessment/:id', element: <ViewInitialAssessment /> },
  { path: '/initial-assessment-list', element: <InitialAssismentList /> },
  { path: '/initial-assessment', element: <InitialAssisment /> },
  { path: '/initial-assessment/:id', element: <InitialAssisment /> },
  { path: '/edit-initial-assessment/:id', element: <InitialAssisment /> },
  { path: '/all-forms', element: <All /> },
  { path: '/chat', element: <Chat /> },
  { path: '/view-refrence-check/:id', element: <ViewRefrenceCheck /> },
  // { path: '/edit-refrence-check/:id', element: <EditRefrenceCheck /> },
  { path: '/create-infection-control', element: <InfectionControl /> },
  { path: '/get-infection-control', element: <GetInfectionControl /> },
  { path: '/infection-control/:id', element: <ViewInfectionControl /> },
  { path: '/edit-infection-control/:id', element: <EditInfectionControl /> },
  { path: '/create-administration-medication', element: <CreateAssistanceMed /> },
  { path: '/get-administration-medication', element: <AssistanceMed /> },
  { path: '/view-assistance-med/:id', element: <ViewAssistanceMed /> },
  { path: '/edit-assistance-med/:id', element: <EditAssistanceMed /> },
  { path: '/create-fall-prevention', element: <CreateFallPrevention /> },
  { path: '/get-fall-prevention', element: <FallPrevention /> },
  { path: '/fall-prevention/:id', element: <ViewFallPrevention /> },
  { path: '/edit-fall-prevention/:id', element: <EditFallPrevention /> },
  { path: '/create-tubercluosis', element: <CreateTubercluosis /> },
  { path: '/tubercluosis', element: <Tubercluosis /> },
  { path: '/tubercluosis/:id', element: <ViewTubercluosis /> },
  { path: '/edit-tubercluosis/:id', element: <EditTubercluosis /> },
  { path: '/activity-schedule', element: <ActivitySchedule /> },
  { path: '/employee-medication', element: <EmployeeMedication /> },
  { path: '/clinical-oversight', element: <ClinicalOversight /> },
  { path: '/clinical-oversight/:id', element: <ClinicalOversight /> },
  { path: '/receipts', element: <Receipts /> },
  { path: '/special-notes', element: <SpecialNote /> },
  { path: '/edit-refrence-check/:id', element: <SignReferenceCheck /> },
  {path: '/list-clinical-oversight', element: <BaseClinicalOversight/> },
  { path: '/view-clinical-oversight/:id', element: <ViewClinicalOversight /> },
];
export const residentRoutes = [
  // { path: '/home', element: <Home /> },
  { path: '/patient_panel', element: <Appointments /> },
  { path: '/patient_Upload_script', element: <FileUpload /> },
  { path: '/intake', element: <IntakeResident /> },
  { path: '/appointment_scheduling', element: <AppointmentScheduling /> },

  { path: '/edit-initial-Assessment-resident/:id', element: <InitialAssessment /> }, 
  { path: '/initial-Assessment-resident-list', element: <InitialAssismentList /> }, 
  { path: '/view-initial-assessment-resident/:id', element: <ViewInitialAssessment /> },

  { path: '/edit-facesheet-resident/:id', element: <FaceSheetResident /> },
  { path: '/faceSheet-resident-list', element: <FaceSheetList /> },
  { path: '/view-faceSheet-resident/:id', element: <ViewFaceSheet /> },

  { path: '/edit-safetyplan-resident/:id', element: <SafetyPlanResident /> },
  { path: '/safety-plan-resident-list', element: <SafetyPlanList /> },
  { path: '/view-safety-plan-resident/:id', element: <ViewSafetyPlan /> },

  { path: '/edit-nursing-assessment-resident/:id', element: <NursingAssessment /> },
  { path: '/nursing-assessment-resident-list', element: <NursingAssismentList /> },
  { path: '/view-nursing-assessment-resident/:id', element: <ViewNursingAssessment /> },

  { path: '/edit-treatmentplan-resident/:id', element: <TreatmentplanUpdate /> },
  { path: '/treatment-plan-resident-list', element: <TreatmentPlanList /> },
  { path: '/view-treatment-plan-resident/:id', element: <ViewTreatmentPlan /> },


  { path: '/edit-residentintakes-resident/:id', element: <ResidentIntakes /> },
  { path: '/resident-intake-resident-list', element: <ResidentIntakeList /> },
  { path: '/view-resident-intake-resident/:id', element: <ViewResidentIntakes /> },
 


  { path: '/initial-Assessment-draft', element: <InitialAssessmentDraft /> },
  { path: '/facesheet-draft', element: <FaceSheetDraft /> },
  { path: '/safetyplan-draft', element: <SafetyPlanDraft /> },
  { path: '/nursing-assessment-draft', element: <NursingAssessmentDarft /> },
  { path: '/treatmentplan-draft', element: <TreatmentplanUpdateDraft /> },
  { path: '/Residentintakes-draft', element: <ResidentIntakesDraft /> },
  { path: '/chatPatient', element: <Chat /> },
  { path: '/booknewappointment', element: <BookAppointmentResident /> },
  { path: '/appointmenthistory', element: <AppointmentHistory /> },
  // { path: '/update-profile', element: <UpdateProfile /> },
  { path: '/manageappointment', element: <ManageAppointments /> },
  { path: '/cancel_appointment', element: <CancelAppointment /> },
  { path: '/progress-chart-resident', element: <ProgressChartResident /> },
  // { path: '/medication-resident', element: <MedicationResident /> },

  { path: '/discharge-summary-resident-list', element: <Discharge /> },
  { path: '/edit-discharge-summary-resident/:id', element: <UpdateDischarge /> },
  { path: '/view-discharge-summary-resident/:id', element: <ViewDischarge /> },

  { path: '/staff-note-resident-list', element: <StaffNotes /> },
  { path: '/edit-staff-note-resident/:id', element: <UpdateStaffNote /> },
  { path: '/view-staff-note-resident/:id', element: <ViewStaffNote /> },

  { path: '/authorization-resident-list', element: <Authorization /> },
  { path: '/edit-authorization-resident/:id', element: <UpdateAuthorization /> },
  { path: '/view-authorization-resident/:id', element: <ViewAuthorization /> },

  { path: '/mars-resident', element: <Mars /> },

  { path: '/informed-consent-resident-list', element: <InformedConsent /> },
  { path: '/edit-informed-consent-resident/:id', element: <UpdateInform /> },
  { path: '/view-informed-consent-resident/:id', element: <ViewInform /> },

  { path: '/prn-log-resident-list', element: <PRNform /> },
  { path: '/edit-prn-log-resident/:id', element: <UpdatePrn /> },
  { path: '/view-prn-log-resident/:id', element: <ViewPrn /> },

  { path: '/refusal-resident-list', element: <Refusal /> },
  { path: '/edit-refusal-resident/:id', element: <UpdateRefusal /> },
  { path: '/view-refusal-resident/:id', element: <ViewRefusal /> },

  // { path: '/nursing-assessment/:id', element: <NursingAssessment /> },

]