/** @format */

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "../../CSS/Sidebar.css";

const HOC = (Wcomponenet) => {
  return function Component() {
    const [hamb, setHamb] = useState(false);
    return (
      <>
        <section className="flex wrapper-content">
          <div
            className={
              hamb
                ? " absolute top-0 z-30 md:w-auto shadow-md sm:bg-[#1A9FB2]  w-60 transition-all md:-left-full left duration-150  h-screen  left-0 "
                : " bg-[#1A9FB2] shadow-md md:static absolute top-0 -left-full h-screen transition-all duration-150 asidebar"
            }
          >
            <Sidebar hamb={hamb} setHamb={setHamb} />
          </div>

          <div
            className={
              hamb
                ? " transition-all py-2 duration-150 w-full h-screen"
                : " w-full py-2 z-50 transition-all duration-150 right-content"
            }
          >
            <div className="content-wrappper wcomp">
              <Wcomponenet />
            </div>
          </div>
        </section>
      </>
    );
  };
};

export default HOC;
