/** @format */

import React, { useState,useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { residentSidebarNav } from "../../assets/Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import { getObjectUrlFromDownloadUrl, LogOutHandler } from "../../Repository/Apis";
import { userProfile } from "../../store/authSlice";
import "../../CSS/Sidebar.css";
const ResidentSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ProfileDetails = useSelector(userProfile);
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  const [currentNavList ,setCurrentNavList] = useState([])
  useEffect(() => {
    if(ProfileDetails?.userType==="Admin"){
      setCurrentNavList(residentSidebarNav)
    }else{
    //   setCurrentNavList(adminEmployeeSidebarNav)
    }
  }, [ProfileDetails?.userType])
  
  const alwaysEnabledItems = ["/Home","/dashboard/patient-tracking","/dashboard/patient-vitals","/patient-list"];

  return (
    <aside
      // style={{ height: "110vh", alignItems: "center" }}
      className="p-4 sm:bg-[rgb(26,159,178)] outer-sidebar "
    >
      {/* Logo */}
      <figure className="flex flex-col items-center">
        <Link to="/Home" style={{ textDecoration: "none" }}> 
          {ProfileDetails?.adminId?.logo||ProfileDetails?.logo ? (
            <img
              style={{
                // maxWidth: "120px",
                maxHeight: "80px",
              }}
              src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo||ProfileDetails?.logo)}
              alt=""
            />
          ) : (
            <img
            style={{
              // maxWidth: "120px",
              maxHeight: "80px",
            }}
              src="/logo.png"
              alt=""
            />
          )}
        </Link>
      </figure>
      {/* Nav-menu */}
      <nav className="outer-siderbar-nav">
      <ul>
      {currentNavList?.map((nav, index) =>
              
              alwaysEnabledItems.includes(nav?.link) || ProfileDetails.userType ==="Admin" ? (
                <li
                key={`nav${index}`}
                onClick={() => navigate(nav.link)}
                className={`menu-item ${location.pathname === nav.link && "active"}`}
                >
                  {typeof nav.icon === 'string' ? (
                      <img src={nav.icon} alt="" />
                    ) : (
                     <nav.icon color={"white"} size={40}/>
                    )}
                  <div className={`text ${location.pathname === nav.link && "active"}`}>
                    {nav.name}
                  </div>
                </li>
              ) 
              : (
                <li
                  key={`nav${index}`}
                  onClick={() => navigate(nav?.link)}
                  className={`menu-item ${location?.pathname === nav?.link && "active"} ${!ProfileDetails?.userPermissions?.view?.split(":").includes(nav?.permissionKey) || !ProfileDetails.userType==="Admin" ? "disabled-link" :''}`}
                >
                  
                 {/* <div className="icon1">{nav.icon}</div> */}
                 {typeof nav?.icon === 'string' ? (
                      <img src={nav?.icon} alt="" />
                    ) : (
                     <nav.icon color={"white"} size={40}/>
                    )}
                  <div className={`text ${location?.pathname === nav?.link && "active"}`}>
                    {nav?.name}
                  </div>
                </li>
              )
            )}
     </ul>
     </nav>
    </aside>
  );
};

export default ResidentSidebar;
