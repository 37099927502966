import { Store } from "react-notifications-component";

export const BaseUrl = process.env.React_App_BaseurlAdmin;

export const auth = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

export const ShowMsg = (title,msg,type) => {
  return Store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1000,
      onScreen: true
    }
  })
};


export const dateConverter = (date) => {
  if(date===null ||date=== undefined) return "None"
 
  const dateArray = date?.split("T" || "/")[0]?.split("-")?.reverse();
  
  
  const reorderedDateArray = [dateArray[1], dateArray[0], dateArray[2]];

  const formattedDate = reorderedDateArray?.join("-");
  
  return formattedDate;
};


