/** @format */

import axios from "axios";
import { showNotification } from "../../Repository/Apis";

export const getData = (setData, url) => {
  return axios
    .get(`${process.env.React_App_Baseurl}${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if (res.data?.data?.length === 0) {
      }
      return setData(res?.data);
    })
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 404) {
        return setData([]);
      }
    });
};
export const getMarsDataByMonthAndYear = (setData, patientId, body,setIsMarsLoading) => {
  setIsMarsLoading(true)
  return axios
    .post(`${process.env.React_App_Baseurl}employee/getMarsByMonth/${patientId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setData(res?.data);
      return res?.data
    })
    .catch((err) => {
      return err?.response?.status||"error";
    }).finally(()=>{
      setIsMarsLoading(false)
    });
};


