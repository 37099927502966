/** @format */

import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AdminSidebar, employeeSidebarNav, Innernav ,ResidentSidebar,residentSidebarNav} from "../../assets/Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import { getObjectUrlFromDownloadUrl, LogOutHandler } from "../../Repository/Apis";
import { userProfile } from "../../store/authSlice";
import "../../CSS/Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ProfileDetails = useSelector(userProfile);
  const [navItem, setNavItem] = React.useState([]);
  const alwaysEnabledItems = ["/Home", "/employee/patient-chart", "/employment","/employee/training", "/dashboard/contacts", "/dashboard/tracking","/dashboard/logs","/dashboard/reference-check","/dashboard/pto","dashboard/employee-performance","/dashboard/staff-schedule","/dashboard/notes","/dashboard/offer-letter","/dashboard/job-description","/dashboard/patient-vitals","/dashboard/patient-tracking", "/special-notes", "/employment-admin ", "/patient-list" ,"/employee/patient-chart" ,"/patient_panel","/intake","/progress-chart-resident"];
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  useEffect(() => {
 
    
    if(ProfileDetails?.userType==="Admin"){
      setNavItem(residentSidebarNav)
    }else if(ProfileDetails?.userType ==="Employee"){
      if(location?.pathname ==='/employment')
      setNavItem(employeeSidebarNav)
    else{
      setNavItem(Innernav)
    }
    }else if(ProfileDetails?.userType==="Patient"){
      setNavItem(ResidentSidebar)
    }
  },[ProfileDetails?.userType])
  
  return (
    <>
      <aside
        // style={{ height: "110vh", alignItems: "center" }}
        className="p-4 sm:bg-[rgb(26,159,178)] outer-sidebar"
      >
        {/* Logo */}
        <figure className="flex flex-col items-center">
          <Link to="/Home" style={{ textDecoration: "none" }}>
            {ProfileDetails?.adminId?.logo||ProfileDetails?.logo ? (
              <img
                style={{
                  // maxWidth: "120px",
                  maxHeight: "80px",
                }}
                src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo||ProfileDetails?.logo)}
                alt=""
              />
            ) : (
              <img
              style={{
                // maxWidth: "120px",
                maxHeight: "80px",
              }}
                src="/logo.png"
                alt=""
              />
            )}
          </Link>
        </figure>

        {/* <div
          className="employeeStatusDiv text-sm mt-3"
          style={{ lineHeight: ".6rem" }}
        >
          <span>
            <p style={{ fontWeight: "500", color: "#fff" }} className='mb-3' >
              <span style={{ opacity: "60%", color: "white" }}>Status:</span>
              Employee
            </p>
            <Button
              style={{
                backgroundColor: "#1A9FB2",
                border: "2px solid white",
                padding: "0 1.3rem",
                color: "white",
              }}
              onClick={logOut}
            >
              Sign Out
            </Button>
          </span>
          <br />
        </div> */}
        {/* Nav-menu */}

        <nav className="outer-siderbar-nav">
          <ul>
            {navItem?.map((nav, index) =>
              
              
              alwaysEnabledItems.includes(nav?.link) || ProfileDetails?.userType==="Admin" ? (
                <li
                key={`nav${index}`}
                onClick={() => navigate(nav.link)}
                className={`menu-item ${location.pathname === nav.link && "active"}`}
                >
                  {typeof nav.icon === 'string' ? (
                      <img src={nav.icon} alt="" />
                    ) : (
                     <nav.icon color={"white"} size={40}/>
                    )}
                  <div className={`text ${location.pathname === nav.link && "active"}`}>
                    {nav.name}
                  </div>
                </li>
              ) 
              // (
              //   (ProfileDetails?.userPermissions?.view?.split(":").includes("em") || ProfileDetails?.userType ==="Admin") && (
              //     <li
              //       key={`nav${index}`}
              //       onClick={() => navigate(nav.link)}
              //       className={`menu-item ${location.pathname === nav.link && "active"}`}
              //     >
              //       {/* <div className="icon1">{nav.icon}</div> */}
              //       {typeof nav.icon === 'string' ? (
              //         <img src={nav.icon} alt="" />
              //       ) : (
              //         <nav.icon color={"white"} size={40}/>
              //       )}
              //       <div className={`text ${location.pathname === nav.link ? "active" : ""}`}>
              //         {location.pathname === nav.link && nav.name.length > 9
              //           ? `${nav.name.slice(0, 9)}..`
              //           : nav.name}
              //       </div>
              //     </li>
                  
              //   )
              // ) 
              : (
                <li
                  key={`nav${index}`}
                  onClick={() => navigate(nav?.link)}
                  className={`menu-item ${location?.pathname === nav?.link && "active"} ${!ProfileDetails?.userPermissions?.view?.split(":").includes(nav?.permissionKey) || !ProfileDetails.userType==="Admin"  ? "disabled-link" :''}`}
                >
                  
                 {/* <div className="icon1">{nav.icon}</div> */}
                 {typeof nav?.icon === 'string' ? (
                      <img src={nav?.icon} alt="" />
                    ) : (
                     <nav.icon color={"white"} size={40}/>
                    )}
                  <div className={`text ${location?.pathname === nav?.link && "active"}`}>
                    {nav?.name}
                  </div>
                </li>
              )
            )}
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
