/** @format */

import React, { useEffect, useState } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import { getApi, removeApi } from "../../../Repository/Apis";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import NavWrapper from "../../../Helper/NavWrapper";
import NoFound from "../../../components/Loader/NoFound";
import HOC from "../../../Layout/EmployeeBar/HOC";
import CreateNav from "../../../Helper/CreateNav";
import { DateFormtter } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { userProfile } from "../../../store/authSlice";

const AllAppendix = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const ProfileDetails = useSelector(userProfile);

  const fetchHandler = () => {
    getApi({
      url: "employee/getAllAppendix",
      setLoading,
      setResponse: setData,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const isAdmin = ProfileDetails.userType === 'Admin';

  const items = data?.data;

  const deleteHandler = async (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `admin/deleteAppendix/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  return (
    <>
      <CreateNav
        title={"TB Risk Assessment"}
        link={"/appendix"}
        isAuthorizedToCreate={isAdmin ? false : true}
      />

      {loading ? (
        <Loader />
      ) : items ? (
        <Container>

          <Table responsive bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Positive Test for TB</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item) => (
                item.employeeId &&
                <tr>
                  <td> {item?.name} </td>
                  <td> {DateFormtter(item?.nameDate)} </td>
                  <td> {item?.positiveTestForTB} </td>
                  <td>
                    <div className="icon-joiner">
                      <Link
                       className="view-btn"
                        to={`/sign-appendix/${item.employeeId?._id}`}
                      >
                        <i className="fa-solid fa-eye" />
                      </Link>
                      {ProfileDetails.userType === "Admin"  && (
                          <Link className="edit-btn" to={`/sign-appendix/${item.employeeId?._id}`}>
                            <i className="fa-solid fa-edit" />
                          </Link>
                        )}
                      {
                         (
                          <Link className="del-btn" onClick={() => deleteHandler(item?._id)}>
                            <i className="fa-solid fa-trash-can"/>
                          </Link>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      ) : (
        <Container>
          <NoFound msg={"No Data Found"} />
        </Container>
      )}
    </>
  );
};

export default HOC({ Wcomponenet: AllAppendix, isNavbar: false });
