/** @format */

import React from "react";
import { Alert, Container } from "react-bootstrap";

const NoFound = () => {
  return <Alert className="mt-3"> No Results Found</Alert> ;
};

export default NoFound;
