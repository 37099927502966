/** @format */
import { useEffect, useRef, useState } from "react";
import { Toast, Dropdown, Modal } from "react-bootstrap";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ClipLoader } from "react-spinners";
import {
  createFirebaseDocumentGroup,
  getApi,
  getObjectUrlFromDownloadUrl,
  UploadImage,
  removeApi,
  showNotification,
} from "../Repository/Apis";
import {
  fetchPaitentName,
  debouncedSetQuery,
  DateFormtter,
  removeMemberGroup,
  addMemberToDocument,
} from "../utils/utils";
import { ProfileImg, defaultUserImg } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import { fetchDocumentId } from "../store/chatSlice";

export const NotificationToast = ({ show, setShow, handleClose, notifications,setNotifications }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notificationContainerRef = useRef(null);
  const ProfileDetails = useSelector(userProfile);
  useEffect(() => {
    if (show) {
      getApi({
        url: "employee/allNotification",
        setResponse: setNotifications,
        setLoading,
      });
    }
  }, [show, setNotifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationContainerRef.current &&
        !notificationContainerRef.current.contains(event.target)
      )
        setShow(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setShow]);

  const handleOnNotificationClick = async (i) => {
    await removeApi({url: `employee/readNotification/${i._id}`, showToast: false});

    if (i?.TherapySession) {
      navigate(`/update-therapy-log/${i?.TherapySession}`);
    } else if (i?.mileageLog) {
      navigate(`/update-milega-log/${i?.mileageLog}`);
    } else if (i?.progressNote) {
      navigate(`/progree-note/${i?.progressNote}`);
    } else if (i?.StaffingNote) {
      navigate(`/update-staff-note/${i?.StaffingNote}`);
    } else if (i?.ADLTrackingForm) {
      navigate(`/update-dtf/${i?.ADLTrackingForm}`);
    } else if (i?.authorizationForReleaseOfInformation) {
      navigate(`/update-authorization/${i?.authorizationForReleaseOfInformation}`);
    } else if (i?.notes && ((i?.title ==="Incident Report part-1" || i?.title ==="Incident Report part-2") || (i?.title ===
      "Incident Report part-1 Signature Required" || i?.title === 
      "Incident Report part-2 Signature Required") ) ) {
      navigate(`/update-incident/${i?.notes}`);
    } else if (i?.ContactNote) {
      navigate(`/update-contact-note/${i?.ContactNote}`);
    } else if (i?.MedicationReconciliation) {
      navigate(`/update-reconciliation/${i?.MedicationReconciliation}`);
    } else if (i?.PrnMedicationLog) {
      navigate(`/update-prn/${i?.PrnMedicationLog}`);
    } else if (i?.mentalStatusReport) {
      navigate(`/update-mental-status/${i?.mentalStatusReport}`);
    } else if (i?.DischargeSummary) {
      navigate(`/update-discharge/${i?.DischargeSummary}`);
    } else if (i?.financialTransactionsRecord) {
      navigate(`/edit-record/${i?.financialTransactionsRecord}`);
    } else if (i?.medicationOpioidCount) {
      navigate(`/update-count/${i?.medicationOpioidCount}`);
    } else if (i?.informedConsentForMedication) {
      navigate(`/update-informed/${i?.informedConsentForMedication}`);
    } else if (i?.refusalMedicalTreatment) {
      navigate(`/update-refusal/${i?.refusalMedicalTreatment}`);
    } else if (i?.OfferLetter){
      navigate(`/sign-offer-letter-form/${i.OfferLetter}`);
    } else if (i?.personalInformation) {
      navigate(`/sign-personal/${i?.personalInformation?.employeeId}`);
    } else if (i?.appendixTBScreeningAssessment) {
      navigate(`/sign-appendix/${i?.appendixTBScreeningAssessment?.employeeId}`);
    } else if (i?.referenceCheck) {
      navigate(`/edit-refrence-check/${i?.referenceCheck}`);
    } else if (i?.APSSearchConsent) {
      navigate(`/edit-aps/${i?.APSSearchConsent}`);
    } else if (i?.onSiteFacility) {
      navigate(`/edit-on-site/${i?.onSiteFacility}`);
    } else if (i?.EmployeeInServiceLog) {
      navigate(`/edit-service-log/${i?.EmployeeInServiceLog}`);
    } else if (i?.skillAndKnowledge) {
      navigate(`/edit-skill-training/${i?.skillAndKnowledge}`);
    } else if (i?.timeOffRequest) {
      navigate(`/edit-time-off-request/${i?.timeOffRequest}`);
    } else if (i?.PerformanceReview) {
      navigate(`/employee-performance/${i.PerformanceReview}`);
    } else if (i?.InfectionControlTraining) {
      navigate(`/edit-infection-control/${i.InfectionControlTraining}`);
    } else if (i?.AssistanceWithSelfAdministration) {
      navigate(`/edit-assistance-med/${i.AssistanceWithSelfAdministration}`);
    } else if (i?.FallPreventionAndFallRecoveryTraining) {
      navigate(`/edit-fall-prevention/${i.FallPreventionAndFallRecoveryTraining}`);
    } else if (i?.TuberculosisTraining) {
      navigate(`/edit-tubercluosis/${i.TuberculosisTraining}`);
    } else if (i?.jobDescription) {
      navigate(`/sign-job-description/${i.jobDescription}`);
    } else if(i.initialAssessment){
      navigate(`/initial-assessment/${i.initialAssessment?.patientId}`);
    } else if(i.residentSafetyPlan){
      navigate(`/safety-plan/${i.residentSafetyPlan}`);
    } else if (i?.TreatmentPlan) {
      navigate(`/treatment-plan/${i.TreatmentPlan}`);
    } else if (i?.faceSheet) {
      navigate(`/faceSheet/${i.faceSheet}`);
    } else if (i?.nursingAssessment) {
      navigate(`/nursing-assessment/${i.nursingAssessment}`);
    } else if (i?.StaffScheduleSigners) {
      navigate(`/time-sheet/${i.StaffScheduleSigners}`);
    } else if (i?.notes && ((i?.title !=="Incident Report part-1" || i?.title !=="Incident Report part-2") || (i?.title !==
      "Incident Report part-1 Signature Required" || i?.title !== 
      "Incident Report part-2 Signature Required") )) {
      navigate(`/special-notes`);
    }else if (i?.title==="Resident Intake Signature Required" ||i?.title=== "Resident Intake updated.") {
      navigate(`/edit-resident-intake/${i?.residentIntake?.patientId}`);
    }
}
const handleNotificationClick = async (notifi) => {
  await removeApi({url: `Patient/readNotification/${notifi._id}`, showToast: false});

  if (notifi?.faceSheet) {
    navigate(`/facesheet-resident`);
  } else if (notifi?.residentSafetyPlan) {
    navigate(`/safetyplan-resident`);
  } else if (notifi?.initialAssessment) {
    navigate(`/initial-Assessment-resident`); 
  } else if (notifi?.residentIntake) {
    navigate(`/residentintakes-resident`);
  } else if (notifi?.TreatmentPlan) {
    navigate(`/treatmentplan-resident`);
  } else if (notifi?.nursingAssessment) {
    navigate(`/nursing-assessment-resident`);
  }else if(notifi?.informedConsentForMedication){
    navigate(`/create-informed-consent-resident`);
  }else if(notifi?.PrnMedicationLog){
    navigate(`/create-prn-log-resident/${notifi?.PrnMedicationLog}`);
  }else if(notifi?.authorizationForReleaseOfInformation){
    navigate(`/create-authorization-resident`);
  }else if(notifi?.StaffingNote){
    navigate(`/create-staff-note-resident`);
  }else if(notifi?.refusalMedicalTreatment){
    navigate(`/create-refusal-resident`);
  }else if(notifi?.DischargeSummary) {
    navigate("/create-discharge-summary-resident")
  }
};
  return (
    <div className="Notification_toast">
      <div className="container" ref={notificationContainerRef}>
          <Toast style={{ zIndex: "1", backgroundColor: "#fff" }} show={show} onClose={handleClose}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Notifications</strong>
            </Toast.Header>
            {loading ? (
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <ClipLoader />
              </div>
            ) : (
              <>
                <div className="notifications">
                  {notifications?.data?.map((i, index) => (
                    <p className="notification-text" key={`notification${index}`} onClick={()=>{ProfileDetails?.userType==="Patient"? handleNotificationClick(i):handleOnNotificationClick(i)}}>
                      <span>{i.body}</span>
                      <span className="time">
                        {i.updatedAt && DateFormtter(i.updatedAt)}{" "}
                        {i.updatedAt?.split("T")?.[1]?.slice(0, 5)}{" "}
                      </span>
                    </p>
                  ))}
                </div>
                {!notifications?.data?.length && <p style={{ color: "#1a9fb2", textAlign: "center", fontWeight: 600 }}>No Notifications</p>}
              </>
            )}
          </Toast>
      </div>
    </div>
  );
};

export const CreateGroup = ({ show, handleClose }) => {
  const [allEmployees, setAllEmployess] = useState({});
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [step, setStep] = useState(1);
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const dispatch = useDispatch();
  const ProfileDetails = useSelector(userProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      getApi({
        url: `admin/getUserForChat?userType=Employee&limit=${limit}&search=${search}`,
        setResponse: setAllEmployess,
        setLoading,
      });
    }
  }, [limit, show, search]);

  const hasMore =
    allEmployees?.data?.totalDocs > allEmployees?.data?.docs?.length;

  const customDebounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const loadMore = customDebounce(() => {
    if (
      limit === allEmployees?.data?.totalDocs ||
      limit < allEmployees?.data?.totalDocs
    ) {
      setLimit(limit + 25);
    }
  }, 500);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: loading,
  });

  const handleAdd = (object) => {
    const alreadyPresent = ids?.some((i) => i._id === object?._id);
    if (alreadyPresent) {
      const filteredObj = ids?.filter((i) => i._id !== object?._id);
      setIds(filteredObj);
    } else {
      setIds((prev) => [...prev, object]);
    }
  };

  const handleImage = (file) => {
    if(file){
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({message:"Please select a valid JPG or PNG image.",type:"default"});
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({message:"File size should be less than 2 MB.",type:"default"});
        return;
      }
    }
    UploadImage(file, setImg);
  };

  const openInput = () => {
    document.getElementById("file").click();
  };

  const payload = {
    Admin: ProfileDetails,
    title,
    image: img,
    members: ids,
    membersId: ids?.map((i) => i._id),
    text: [],
  };

  const createDocument = () => {
    dispatch(
      createFirebaseDocumentGroup({
        payload,
        collectionName: process.env.React_App_Firebase_Group_CollectionName,
        navigate,
        navigationLink: "/chat",
        handleClose,
        recipientName: "",
      })
    );
  };

  const filterdEmployess = allEmployees?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );

  return (
    <Modal show={show} onHide={handleClose} placement="end">
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
        
          {step === 2 ? "New Group" : "Add group memebers"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {step === 2 &&<div className="next-step" onClick={() => setStep(1)}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>}
        <div style={{ postition: "relative" }}>
          {step === 1 && (
            <>
              <div className="selected-ids">
                {ids?.map((i, index) => (
                  <div className="main" key={`user${index}`}>
                    <img
                      src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                      className="original-img"
                      alt=""
                    />

                    <div className="content">
                      <p className="heading">{fetchPaitentName(i)} </p>
                    </div>
                    <i
                      onClick={() => handleAdd(i)}
                      className="fa-solid fa-xmark"
                    ></i>
                  </div>
                ))}
              </div>

              <div className="serach-bar">
                <input
                  type="search"
                  onChange={(e) =>
                    debouncedSetQuery({
                      term: e.target.value,
                      setQuery: setSearch,
                    })
                  }
                  placeholder="Search name or number"
                />
              </div>

              {filterdEmployess?.length > 0 && (
                <>
                  <div className="create-new-chat-room">
                    {filterdEmployess?.map((i, index) => (
                      <div
                        className="select-employee"
                        onClick={() => handleAdd(i)}
                        key={`user${index}`}
                      >
                        <img
                          src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                          className="original-img"
                          alt=""
                        />

                        <div className="content">
                          <p className="heading text-start">{fetchPaitentName(i)} </p>
                          <p className="faded"> {i.mobileNumber} </p>
                          <p className="faded">{i.email} </p>
                        </div>
                      </div>
                    ))}
                    {loading && (
                      <div style={{ display: "block", margin: "auto" }}>
                        <ClipLoader />
                      </div>
                    )}
                    <div ref={sentryRef}></div>
                  </div>
                </>
              )}
            </>
          )}

          {ids?.length > 0 && step === 1 && (
            <div className="next-step" onClick={() => setStep(2)}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          )}

          {step === 2 && (
            <>
              <div className="group-description">
                <img
                  src={img ? getObjectUrlFromDownloadUrl(img) : ProfileImg}
                  onClick={() => openInput()}
                  alt=""
                />
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleImage(e.target.files[0])}
                />

                <div className="serach-bar">
                  <input
                    type="text"
                    placeholder="Group Name"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <button
                  className="employee_create_btn"
                  onClick={() => createDocument()}
                >
                  Create
                </button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const GroupInfo = ({ show, handleClose, document }) => {
  const [open, setOpen] = useState(false);
  const removeMember = (id) => {
    removeMemberGroup({ memberIdToRemove: id, documentId });
  };
  const documentId = useSelector(fetchDocumentId);

  const toggleFunc = () => {
    handleClose();
    setOpen(true);
  };

  return (
    <>
      <AddMember show={open} handleClose={() => setOpen(false)} />
      <Modal show={show} onHide={handleClose} placement="end">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Group Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="group-description">
            <img
              style={{ cursor: "default" }}
              src={document?.image ? getObjectUrlFromDownloadUrl(document?.image) : defaultUserImg}
              alt=""
            />
            <p className="title"> {document?.title} </p>
          </div>

          <div className="empty-colored-div"></div>
          <div
            className="go-to-chat"
            style={{ borderBottom: "0", paddingLeft: "20px" }}
          >
            <h5 className="fw-bold  w-100 text-start">
              {document?.members?.length} members
            </h5>
          </div>

          <div className="add_member" onClick={() => toggleFunc()}>
            <i className="fa-solid fa-user-plus"></i>
            <p>Add member</p>
          </div>

          <div className="create-new-chat-room">
            {document?.members?.map((i, index) => (
              <div
                className="select-employee"
                style={{ cursor: "default" }}
                key={`user${index}`}
              >
                <img
                  src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                  className="original-img"
                  alt=""
                />

                <div className="content">
                  <p className="heading text-start">{fetchPaitentName(i)} </p>
                  <p className="faded"> {i.mobileNumber} </p>
                  <p className="faded">{i.email} </p>
                </div>

                <div
                  className="eclipse-dropdown"
                  style={{ width: "auto", flex: 1 }}
                >
                  <Dropdown>
                    <Dropdown.Toggle variant="none">
                      <i className="fa-solid fa-caret-down"></i>{" "}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => removeMember(i._id)}>
                        {" "}
                        Remove
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const AddMember = ({ show, handleClose }) => {
  const [allEmployees, setAllEmployess] = useState({});
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [search, setSearch] = useState("");
  const ProfileDetails = useSelector(userProfile);

  useEffect(() => {
    if (show) {
      getApi({
        url: `admin/getUserForChat?userType=Employee&limit=${limit}&search=${search}`,
        setResponse: setAllEmployess,
        setLoading,
      });
      setIds([]);
    }
  }, [limit, show, search]);

  const hasMore =
    allEmployees?.data?.totalDocs > allEmployees?.data?.docs?.length;

  const customDebounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const loadMore = customDebounce(() => {
    if (
      limit === allEmployees?.data?.totalDocs ||
      limit < allEmployees?.data?.totalDocs
    ) {
      setLimit(limit + 25);
    }
  }, 500);

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: hasMore,
    onLoadMore: loadMore,
  });

  const handleAdd = (object) => {
    const alreadyPresent = ids?.some((i) => i._id === object?._id);
    if (alreadyPresent) {
      const filteredObj = ids?.filter((i) => i._id !== object?._id);
      setIds(filteredObj);
    } else {
      setIds((prev) => [...prev, object]);
    }
  };

  const filterdEmployess = allEmployees?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );

  const documentId = useSelector(fetchDocumentId);

  const addMember = async () => {
    await addMemberToDocument({ documentId, newMembers: ids });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add member</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="created-chat-main-div" style={{ position: "relative" }}>
          <div className="selected-ids">
            {ids?.map((i, index) => (
              <div className="main" key={`user${index}`}>
                <img
                  src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                  className="original-img"
                  alt=""
                />

                <div className="content">
                  <p className="heading">{fetchPaitentName(i)} </p>
                </div>
                <i
                  onClick={() => handleAdd(i)}
                  className="fa-solid fa-xmark"
                ></i>
              </div>
            ))}
          </div>
          <div className="serach-bar">
            <input
              type="search"
              onChange={(e) =>
                debouncedSetQuery({
                  term: e.target.value,
                  setQuery: setSearch,
                })
              }
              placeholder="Search name or number"
            />
          </div>
          {loading === true && (
            <div style={{ padding: "10px", textAlign: "center" }}>
              <ClipLoader />
            </div>
          )}
          {filterdEmployess?.length > 0 && (
            <>
              <div className="create-new-chat-room">
                {filterdEmployess?.map((i, index) => (
                  <div
                    className="select-employee"
                    onClick={() => handleAdd(i)}
                    key={`user${index}`}
                  >
                    <img
                      src={i.profilePic ? getObjectUrlFromDownloadUrl(i.profilePic) : defaultUserImg}
                      className="original-img"
                      alt=""
                    />

                    <div className="content">
                      <p className="heading text-start">{fetchPaitentName(i)} </p>
                      <p className="faded"> {i.mobileNumber} </p>
                      <p className="faded">{i.email} </p>
                    </div>
                  </div>
                ))}

                <div ref={sentryRef}></div>
              </div>
            </>
          )}{" "}
          {ids?.length > 0 && (
            <div className="next-step" onClick={() => addMember()}>
              <i className="fa-solid fa-check"></i>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
