/** @format */

import {
  InitialImg,
  NurseImg,
  FaceSheetImg,
  MedImg,
  SafetyPlanImg,
  ResidentIntakeImg,
  homeImg,
  patientChartImg,
  notesImg,
  dischargeImg,
  VectorImg,
  NotesImg,
  MedicationImg,
  TrackingImg,
  PerformaceImg,
  ScheduleImg,
  timeOff,
  TrainingImg,
  ProgressNoteImg,
  DischargeImg,
  ActivityOfDailyLivigImg,
  financialRecordImg,
  stuffingNoteImg,
  authReleaseInfoImg,
  incidentReportImg,
  contactNoteImg,
  pto,
  emP,
  staff,
  notePng,
  HomePng,
  contectPng,
  trackingPng,
  logsPng,
  specialPng,
  informedConsentForm,
  marsImg,
  medicationRecon,
  medicationCount,
  MEDICATIONLOG,
  lrc1031a,
  fw4Img,
  fw9Img
} from "../../assets/index";
import { PiHouseBold } from "react-icons/pi";
import { FaRegFileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoMdTimer } from "react-icons/io";
import { IoTimer } from "react-icons/io5";
import { FaNotesMedical } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { LuFiles } from "react-icons/lu";
import { VscGraph } from "react-icons/vsc";
import { MdTrackChanges } from "react-icons/md";
import { TbChartDotsFilled } from "react-icons/tb";
import { RiLineChartFill } from "react-icons/ri";
import { FaListCheck } from "react-icons/fa6";
import { LiaClipboardListSolid } from "react-icons/lia";
import { IoPersonSharp } from "react-icons/io5";


export const incidentOptions = [
  {
    label: "Altercation/Verbal",
    value: "Altercation/Verbal",
  },
  {
    label: "Altercation/Physical",
    value: "Altercation/Physical",
  },
  {
    label: "Violent Threat/Self",
    value: "Violent Threat/Self",
  },
  {
    label: "Violent Threat/Others",
    value: "Violent Threat/Others",
  },
  {
    label: "Violent Action/Self",
    value: "Violent Action/Self",
  },
  {
    label: "Violent Action/Others",
    value: "Violent Action/Others",
  },
  {
    label: "Trespassing",
    value: "Trespassing",
  },
  {
    label: "Cut/Abrasion",
    value: "Cut/Abrasion",
  },
  {
    label: "Property Loss",
    value: "Property Loss",
  },
  {
    label: "Property Damage",
    value: "Property Damage",
  },
  {
    label: "Vehicular Accident",
    value: "Vehicular Accident",
  },
  {
    label: "Medical Problem/911",
    value: "Medical Problem/911",
  },
  {
    label: "Employee Injury",
    value: "Employee Injury",
  },
  {
    label: "Client/Consumer Injury",
    value: "Client/Consumer Injury",
  },
  {
    label: "Procedural Break",
    value: "Procedural Break",
  },
  {
    label: "Pharmacy Error",
    value: "Pharmacy Error",
  },
  {
    label: "Weapon",
    value: "Weapon",
  },
  {
    label: "Contraband",
    value: "Contraband",
  },
  {
    label: "Alcohol/Drug Use",
    value: "Alcohol/Drug Use",
  },
  {
    label: "Equipment/Utility Failure",
    value: "Equipment/Utility Failure",
  },
  {
    label: "Bio-hazardous Material",
    value: "Bio-hazardous Material",
  },
  {
    label: "AMA",
    value: "AMA",
  },
  {
    label: "Slip/Fall",
    value: "Slip/Fall",
  },
  {
    label: "Rule Violation",
    value: "Rule Violation",
  },
  {
    label: "Seizure",
    value: "Seizure",
  },
  {
    label: "Medication Errors",
    value: "Medication Errors",
  },
  {
    label: "AWOL",
    value: "AWOL",
  },
  {
    label: "Psychiatric Emergency",
    value: "Psychiatric Emergency",
  },
  {
    label: "Abuse/Neglect",
    value: "Abuse/Neglect",
  },
];

export const levelSeverityOptions = [
  {
    label: "Critical/Immediate Attention Required",
    value: "Critical/Immediate Attention Required",
  },
  {
    label: "Serious/Attention Required",
    value: "Serious/Attention Required",
  },
  {
    label: "No medical Attention Required",
    value: "No medical Attention Required",
  },
];

export const patient_chart = [
  {
    src: ProgressNoteImg,
    link: "/employee/patient-chart/progress",
    title: "Progress Note",
    permissionKey: "pn" 
  },
  {
    src: DischargeImg,
    link: "/discharge-summary",
    title: "Discharge",
    permissionKey: "discharge"
  },
  {
    src: ActivityOfDailyLivigImg,
    link: "/DTF",
    title: "Activities of Daily Living Tracking Form",
    permissionKey: "dtf"

  },
  {
    src: financialRecordImg,
    link: "/financial-record",
    title: "Financial Transaction Record",
    permissionKey: "ft"

  },
  {
    src: stuffingNoteImg,
    link: "/staff-note",
    title: "Staffing Note",
    permissionKey: "sn"

  },
  {
    src: authReleaseInfoImg,
    link: "/authorization",
    title: "Authorization for Release of information",
    permissionKey: "ari"
  },
  {
    src: incidentReportImg,
    link: "/incident-report",
    title: "Incident Report Form",
    permissionKey: "inr"
  },
  {
    src: contactNoteImg,
    link: "/contact-note",
    title: "Contact Note",
    permissionKey: "cn"

  },

 
];

export const NotesOptions = [
  {
    src: "/Dashboard2/GroupNotes/Group 17546.png",
    link: "/therapy-notes",
  },
  {
    src: "/Dashboard2/GroupNotes/Group 17686.png",
    link: "/manual-theraphy",
  },
];

export const TherapyNotesOptions = [
  {
    src: "/Dashboard2/GroupNotes/Group 17546.png",
    link: "/therapy-log",
  },
  {
    src: "/Dashboard2/GroupNotes/Tharapy Notes/Group 387.png",
    link: "/milega-log",
  },
];

export const TrainingConstant = [
  {
    src: financialRecordImg,
    link: "/on-site",
    title: "On Site and Facility Orientation Verification",
    permissionKey: "onsfov"
  },
  {
    src: ActivityOfDailyLivigImg,
    link: "/skills-knowledge-training",
    title: "Skill and Knowledge Training",
    permissionKey: "st"
  },
];
export const SecondItem = [
  {
    link: "/get-infection-control",
    img: VectorImg,
    title: "Infection Control",
     permissionKey: "ic"
  },
  {
    link: "/get-administration-medication",
    img: dischargeImg,
    title: "Assistance with Self-Administration of Medication",
     permissionKey: "asam"
  },
  {
    link: "/get-fall-prevention",
    img: NotesImg,
    title: "Fall Prevention and Recovery Training",
    permissionKey: "fprt"
  },
  {
    link: "/tubercluosis",
    img: MedicationImg,
    title: "Tubercluosis Training",
    permissionKey: "tc"
  },
];

export const MainSidebar = [
  {
    icon: homeImg,
    link: "/Home",
    name: "Home",
  },
  {
    icon: patientChartImg,
    link: "/employee/patient-chart",
    name: "Resident Chart",
  },
  {
    icon: notesImg,
    link: "/employment",
    name: "Employment Information",
  },
  {
    icon: VectorImg,
    link: "/patient-list",
    name: "Resident List",
  },
];
export const AdminSidebar = [
  {
    icon: HomePng,
    link: "/dashboard/homepage",
    name: "Home",
  },
  {
    icon: contectPng,
    link: "/dashboard/contacts",
    name: "Users",
  },
  {
    icon: trackingPng,
    link: "/dashboard/tracking",
    name: "Tracking",
  },
  {
    icon: logsPng,
    link: "/dashboard/logs",
    name: "Logs",
  },
  {
    icon: IoMdTimer,
    link: "/dashboard/staff-schedule",
    name: "Staff Schedule",
  },
  {
    icon: IoTimer ,
    link: "/dashboard/activity-schedule",
    name: "Activity Schedule",
  },
  {
    icon: FaNotesMedical,
    link: "/dashboard/notes",
    name: "Notes Library",
  },
  {
    icon: LiaClipboardListSolid ,
    link: "/special-notes",
    name: "Special Note",
  },
  {
    icon: IoPersonSharp ,
    link: "/employment-admin",
    name: "Employee ",
  },
  {
    icon: RiLineChartFill ,
    link: "/employee/patient-chart",
    name: "Resident",
  },
];

export  const ResidentSidebar = [
  {
    icon: PiHouseBold,
    link: "/patient_panel",
    name: "Home",
  },
  {
    icon: FaRegFileAlt,
    link: "/intake",
    name: "Intake",
  },
  {
    icon: TbChartDotsFilled ,
    link: "/progress-chart-resident",
    name: "Progress Chart",
  },

]

export const Innernav = [
  {
    icon: homeImg,
    link: "/Home",
    name: "Home",
    permissionKey:"hm"
  },
  {
    icon: NotesImg,
    link: "/therapy-log",
    name: "Therapy Notes",
    permissionKey:"tn"
  },
  {
    icon: "/Dashboard2/GroupNotes/Group 17546.png",
    link: "/milega-log",
    name: "Mileage Log",
    permissionKey:"ml"
  },
  {
    icon: patientChartImg,
    link: "/employee/patient-chart",
    name: "Resident Chart",
    permissionKey:"rc"
  },
  {
    icon: fw4Img,
    link: "/vitals",
    name: "Resident Vitals",
    permissionKey:"rv"
  },
  {
    icon: fw9Img,
    link: "/patient-tracking",
    name: "Resident Tracking",
    permissionKey:"rt"
  },
  {
    icon: MedicationImg,
    link: "/medications",
    name: "Medications",
    permissionKey:"em"
  },
];

export const ShiftOptions = [
  {
    label: "7am-3pm",
    value: "7am-3pm",
  },
  {
    label: "3pm-11pm",
    value: "3pm-11pm",
  },
  {
    label: "11pm-7am",
    value: "11pm-7am",
  },
  {
    label: "8am-4pm",
    value: "8am-4pm",
  },
  {
    label: "4pm-12am",
    value: "4pm-12am",
  },
  {
    label: "12am-8am",
    value: "12am-8am",
  },
  {
    label: "7am-7pm",
    value: "7am-7pm",
  },
  {
    label: "7pm-7am",
    value: "7pm-7am",
  },
  {
    label: "8am-8pm",
    value: "8am-8pm",
  },
  {
    label: "8pm-8am",
    value: "8pm-8am",
  },
];

export const authorizeOptions = [
  {
    label: "Mental Health",
    value: "Mental Health",
  },
  {
    label: "Substance abuse",
    value: "Substance abuse",
  },
  {
    label: "Medical information",
    value: "Medical information",
  },
  {
    label: "Pharmacy",
    value: "Pharmacy",
  },
  {
    label: "Current medication",
    value: "Current medication",
  },
  {
    label: "Psychotherapy notes",
    value: "Psychotherapy notes",
  },
  {
    label: "Progress notes",
    value: "Progress notes",
  },
  {
    label: "Immunization records",
    value: "Immunization records",
  },
];

export const shiftOptions = [
  {
    value: "07:00 Am",
    label: "07:00 Am",
  },
  {
    value: "02:00 Pm",
    label: "02:00 Pm",
  },
  {
    value: "08:00 Pm",
    label: "08:00 Pm",
  },
];

export const shiftBeginingOption = [
  {
    label: "7am",
    value: "7am",
  },
  {
    label: "8am",
    value: "8am",
  },
  {
    label: "9am",
    value: "9am",
  },
  {
    label: "10am",
    value: "10am",
  },
  {
    label: "11am",
    value: "11am",
  },
  {
    label: "12pm",
    value: "12pm",
  },
  {
    label: "1pm",
    value: "1pm",
  },
  {
    label: "2pm",
    value: "2pm",
  },
  {
    label: "3pm",
    value: "3pm",
  },
  {
    label: "4pm",
    value: "4pm",
  },
  {
    label: "5pm",
    value: "5pm",
  },
  {
    label: "6pm",
    value: "6pm",
  },
  {
    label: "7pm",
    value: "7pm",
  },
];

export const mentalStatusObj = {
  behaviourAppointmentOptions: [
    "Anxiety",
    "Depression",
    "Crying",
    "Racing thoughts",
    "Other",
  ],
  apperanceOptions: ["Well groomed", "Casually groomed", "Tattered", "Other"],
  OrientationOptions: [
    "Oriented x 3 (to time, place, situation)",
    "Partly oriented",
    "Other",
  ],
  affectOptions: [
    "Normal in range",
    "appropriate to the situation",
    "congruent with mood",
    "Blunted or restricted (little expressed emotion)",
    "flat (no expressed emotion)",
    "labile or very variable",
    "Other",
  ],
  speechOptions: [
    "Fluent. Normal rate",
    "Normal volume",
    "Halting speech",
    "Selective mute",
    "Halting speech",
    "word-finding difficulties",
    "Other",
  ],
  thoughtOptions: [
    "Normal thought content",
    "Fixed ideas",
    "delusions",
    "hallucinations (auditory and/or visual, etc.)",
    "Other",
  ],
  orientationOptions: [
    "Alert",
    "Hypervigilant",
    "Drowsy",
    "lethargic",
    "stuporous",
    "asleep",
    "comatose",
    "confused",
    "fluctuating",
    "Other",
  ],
  memoryOptions: [
    "intact for recent memory",
    "Intact for remote memory",
    "Limited or deficient for recent and/or remote memory",
    "Other",
  ],
  reliabilityOptions: [
    "Good judgement",
    "Fair judgement",
    "Poor judgement",
    "Other",
  ],
  moodOptions: [
    "Normal or euthymic",
    "Sad or dysphoric",
    "hopeless",
    "variable mood",
    "irritable",
    "worried or anxious",
    "expansive or elevated mood",
    "Other",
  ],
};

export const FilesNames = [
  "Progress Note",
  "Discharge",
  "Activities of Daily Living Tracking Form",
  "Financial Transactions Record",
  "Staffing Note",
  "Authorization for Release of Information",
  "Incident Report",
  "Contact Note",
  "Mars",
  "Medication Reconciliation",
  "Medication Count",
  "Informed Consent for Medications",
  "PRN Medication Log",
  "Mental Status",
  "Refusal of Medical Treatment Form",
  "Appointment Tracking Log",
];

export const IntakeArr = [
  {
    img: InitialImg,
    title: "Initial Assessment",
    link: "/initial-assessment-list",
    permissionKey:"iass"
  },
  {
    img: NurseImg,
    title: "Nursing  Assessment",
    link: "/nursing-assessment-list",
    permissionKey:"nass"
  },
  {
    img: MedImg,
    title: "Treatment Plan",
    link: "/treatment-plan-list",
    permissionKey:"tp"
  },
  {
    img: FaceSheetImg,
    title: "Face Sheet",
    link: "/faceSheet-list",
    permissionKey:"fs"
  },
  {
    img: SafetyPlanImg,
    title: "Safety Plan",
    link: "/safety-plan-list",
    permissionKey:"sp"
  },
  {
    img: ResidentIntakeImg,
    title: "Resident Intakes",
    link: "/resident-intake-list",
    permissionKey:"ri"
  },
  
];



export const IntakeArrAdmin = [ 
  {
    img: NotesImg,
    title: "Therapy Notes",
    link: "/therapy-log",
  },
  {
    img: authReleaseInfoImg,
    link: "/list-clinical-oversight",
    title: "Clinical Oversight",
  },
  {
    img:  "/Dashboard2/GroupNotes/Group 17546.png",
    title: "Mileage Log",
    link: "/milega-log",
  },
  {
    img: TrainingImg,
    title: "Resident Vitals",
    link: "/vitals",
  },
  {
    img: contactNoteImg,
    title: "Resident Tracking",
    link: "/patient-tracking",
  },
  {
    img: MedicationImg,
    link: "/employee-medication",
    title: "Resident Medication"
  },
  {
    img: marsImg,
    link: "/mars",
    title: "MARS"
  },
  {
    img: medicationRecon,
    link: "/reconciliation",
    title: "Medication reconciliation"
  },
  {
    img: medicationCount,
    link: "/employee/medications/medication-count",
    title: "Medication Count"
  },
  {
    img: informedConsentForm,
    link: "/employee/medications/informed-consent",
    title: "Informed Consent for Medications"
  },
  {
    img: MEDICATIONLOG,
    link: "/employee/medications/prn-form",
    title: "PRN"
  },
  {
    link: "/mental-status",
    img: VectorImg,
    title: "Mental Status",
  },
  {
    link: "/refusal",
    img: dischargeImg,
    title: "Refusal of Medical Treatment Form",
  },
  {
    link: "/tracking-log",
    img: NotesImg,
    title: "Appointment Tracking Log",
  },

];

export const employeeSidebarNav = [
  {
    icon: homeImg,
    link: "/Home",
    name: "Home",
    permissionKey:"hm"
  },
  {
    icon: dischargeImg,
    link: "/basic-information",
    name: "Employment Application ",
    permissionKey : "binf"
  },
  {
    icon: notesImg,
    link: "/employment",
    name: "Employment Information",
    permissionKey : "empi"
  },

  {
    icon: TrainingImg,
    link: "/employee/training",
    name: "Training",
    permissionKey : "trai"
  },
  {
    icon: timeOff,
    link: "/get-time-of-request",
    name: "Time Off Request",
    permissionKey :"tr"
  },
  {
    icon: ScheduleImg,
    link: "/scheduleGroup",
    name: "Time Sheet/Staff Schedule",
    permissionKey :"timesheet"
  },
  {
    icon: lrc1031a,
    link: "/employee-performance",
    name: "Employee Performance",
    permissionKey : "perf"
  },
  {
    icon: TrackingImg,
    link: "/employee-tracking",
    name: "Employee Tracking/ Upload",
    permissionKey : "etracking"
  },
];
export const residentSidebarNav = [
  {
    icon: homeImg,
    link: "/dashboard/homepage",
    name: "Home",
    permissionKey:"hm"
  },
  {
    icon: dischargeImg,
    link: "/dashboard/patient-tracking",
    name: "Resident Tracking",
    permissionKey : "binf"
  },
  {
    icon: dischargeImg,
    link: "/employee/patient-chart",
    name: "Resident",
    permissionKey : "res"
  },
  // {
  //   icon: notesImg,
  //   link: "/dashboard/patient-vitals",
  //   name: "Resident Vitals",
  //   permissionKey : "empi"
  // },
  {
    icon: TrainingImg,
    link: "/patient-list",
    name: "All Residents",
    permissionKey : "trai"
  },

];

export const adminEmployeeSidebarNav = [
  {
    icon: homeImg,
    link: "/dashboard/homepage",
    name: "Home",
  },
  // {
  //   icon: dischargeImg,
  //   link: "/basic-information",
  //   name: "Employment Application ",
  // },
  {
    icon: notesImg,
    link: "/employment",
    name: "Employment Information ",
  },

  {
    icon: TrainingImg,
    link: "/employee/training",
    name: "Training",
  },
  // {
  //   icon: timeOff,
  //   link: "/get-time-of-request",
  //   name: "Time Off Request",
  // },
  {
    icon: ScheduleImg,
    link: "/time-sheet",
    name: "Time Sheet",
  },
  // {
  //   icon: PerformaceImg,
  //   link: "/employee-performance",
  //   name: "Employee Performance",
  // },
  {
    icon: TrackingImg,
    link: "/employee-tracking",
    name: "Employee Tracking/ Upload",
  },
  {
    icon: PerformaceImg,
    link: "/employee-list",
    name: "Employee List",
  },
]

export const mobileSidebarOptions = [
  {
    icon: homeImg,
    link: "/Home",
    name: "Home",
  },
  {
    icon: patientChartImg,
    link: "/employee/patient-chart",
    name: "Patient chart",
  },
  {
    icon: notesImg,
    link: "/employment",
    name: "Employment Information",
  },
  {
    icon: VectorImg,
    link: "/patient-list",
    name: "Patient List",
  },
  {
    icon: NotesImg,
    link: "/group-notes",
    name: "Group Notes",
  },

  {
    icon: MedicationImg,
    link: "/vitals",
    name: "Patient Vitals",
  },
  {
    icon: TrackingImg,
    link: "/patient-tracking",
    name: "Resident Tracking",
  },
  {
    icon: MedicationImg,
    link: "/medications",
    name: "Medications",
  },
  {
    icon: dischargeImg,
    link: "/basic-information",
    name: "Employment Application ",
  },
  {
    icon: notesImg,
    link: "/employment",
    name: "Employment Information ",
  },
  {
    icon: TrainingImg,
    link: "/employee/training",
    name: "Training",
  },
  {
    icon: timeOff,
    link: "/get-time-of-request",
    name: "Time Off Request",
  },
  {
    icon: ScheduleImg,
    link: "/scheduleGroup",
    name: "Time Sheet/Staff Schedule",
  },
  {
    icon: FaUserAlt,
    link: "/employee-performance",
    name: "Employee Performance",
  },
  {
    icon: TrackingImg,
    link: "/employee-tracking",
    name: "Employee Tracking/ Upload",
  },
];

export const randomColor = [
  {
    bg: "#7469B6",
    color: "#fff",
  },
  {
    bg: "#AD88C6",
    color: "#fff",
  },
  {
    bg: "#68D2E8",
    color: "#fff",
  },
  {
    bg: "#640D6B",
    color: "#fff",
  },
  {
    bg: "#0A6847",
    color: "#fff",
  },
  {
    bg: "#32012F",
    color: "#fff",
  },
  {
    bg: "#00215E",
    color: "#fff",
  },
  {
    bg: "#153448",
    color: "#fff",
  },
  {
    bg: "#3C5B6F",
    color: "#fff",
  },
  {
    bg: "#4793AF",
    color: "#fff",
  },
  {
    bg: "#1679AB",
    color: "#fff",
  },
  {
    bg: "#C0D6E8",
    color: "#000",
  },
  {
    bg: "#49243E",
    color: "#fff",
  },
  {
    bg: "#453F78",
    color: "#fff",
  },
  {
    bg: "#496989",
    color: "#fff",
  },
];

export const weekDays = [
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
  { label: "Sunday", value: "7" }
];


