import axios from "axios";

import { ShowMsg, BaseUrl, auth } from "./mainfile";

export const singleData = (url, id) => {
  try {
    return axios.get(`${BaseUrl}/${url}/${id}`, auth());
  } catch (error) {
    ShowMsg("Error", error.message, "error");
    
  }
};
export const getAllData = (url) => {
  try {
    return axios.get(`${BaseUrl}/${url}`, auth());
  } catch (error) {
    ShowMsg("Error", error.message, "error");
   
  }
};

export const postData = (url, data) => {
 
  try {
    return axios.post(`${BaseUrl}/${url}`, data, auth());
  } catch (error) {
    ShowMsg("Error", error.message, "error");
    
  }
};

export const updateData = (url, data, id) => {
  try {
    return axios.put(`${BaseUrl}/${url}/${id}`, data, auth());
  } catch (error) {

    ShowMsg("Error", error.message, "error");
   
  }
};

export const deleteData = (url, id, getData) => {
  try {
    axios
      .delete(`${BaseUrl}/${url}/${id}`, auth())
      .then((res) => {
        getData();
        ShowMsg("Success", res.data.message, "success");
      })
      .catch((error) => {
        if (error.response?.status===404) {
          getData();
          getData();
        }
      });
  } catch (error) {
  
    ShowMsg("Error", error.message, "error");
    
  }
};

export const getDataById = (url, id,year,month) => {
  try {
    return axios.get(`${BaseUrl}/${url}`, {
      params: {
        employeeId: id,
        year: year ? year : null,
        month: month ? month : null,
      },
      ...auth(),
    });
  } catch (error) {
    ShowMsg("Error", error.message, "error");
   
  }
}
export const patient_form_treatment_get = async (id) => {
  try {
    const response = await axios.get(`${BaseUrl}/Patient/getPatientDetails/${id}`, {
      ...auth(),
    });
    return response.data.data; 
  } catch (error) {
    ShowMsg("Error", error.message, "error");
    throw error; 
   }
};

export const GenerateOtp = async ({ payload }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/admin/otpChallenge`,
      payload
    );
    return res?.status
  } catch {
    ShowMsg("", 'Failed to send OTP. Please try again.', "error");
  }
}
export function getObjectUrlFromDownloadUrl(downloadUrl) {
  try {
    const objectUrl =  encodeURI(`https://d2u4q4ytylw93t.cloudfront.net/${downloadUrl}`);
    return objectUrl;
  } catch (error) {
    console.error('Error fetching and creating object URL:', error);
    return null;
  }
}