/** @format */

import axios from "axios";
import { Store } from "react-notifications-component";
import { LoginSlice, LOGOUT, UpdateUserProfile } from "../store/authSlice";
import { auth, db } from "../firebase";
import { collection, addDoc, getDocs, query, where, deleteDoc, doc } from "firebase/firestore";
import { setDocumentID } from "../store/chatSlice";
import { ProfileImg } from "../assets";

const errorMessage = "Please fill all mandatory fields";
const successMessage = "Form submitted successfully";
const removeMessage = "Removed";

export const postApi = async (setLoading, url, payload) => {
  setLoading(true);
  try {
    const res = await axios.post(
      `${process.env.React_App_Baseurl}${url}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setLoading(false);
    showNotification({ message: res.data.message ? res.data.message : successMessage });
    return res;
  } catch (e) {
    setLoading(false);
    showNotification({ message: e?.response?.data.message ? e?.response?.data?.message: errorMessage, type: "danger" });
  }
};

export const UploadImage = async (file, setResponse,id,fileName) => {
  const fd = new FormData();
  fd.append("image", file);
  fd.append("type", fileName);
  try {
    const res = await axios.post(
      `${process.env.React_App_Baseurl}employee/updateImage/${id}`,
      fd,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setResponse(res?.data?.data);
  } catch {}
};

export const downloadReport = (handlePrint) => {
  var elements = document.getElementsByClassName("hidePrint");
  for (var i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }
  handlePrint();
  setTimeout(() => {
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "block";
    }
  }, 1000);
};

export const DateInMMDDYY = (date) => {
  const originalDate = new Date(date);
  const timezoneOffset = originalDate?.getTimezoneOffset();
  const adjustedTime = new Date(
    originalDate?.getTime() + timezoneOffset * 60000
  );
  const year = adjustedTime?.getFullYear();
  const month = adjustedTime?.getMonth() + 1;
  const day = adjustedTime?.getDate();

  return `${month < 10 ? `0${month}` : month}/${
    day < 10 ? `0${day}` : day
  }/${year}`;
};

// Notification Module
export const showNotification = ({ message, type = "success" }) => {
  Store.addNotification({
    title: "",
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1000,
      onScreen: true,
    },
  });
};

// Api Module
export const getApi = async ({
  url,
  setResponse,
  setLoading,
  additionalFunctions = [],
  setErrorMessage,
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.get(`${process.env.React_App_Baseurl}${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setResponse(res.data);
    additionalFunctions.forEach((func) => {
      if (typeof func === "function") {
        func();
      }
    });
  } catch (e) {
    setResponse({});
    if (setErrorMessage){
      setErrorMessage(e?.response?.data?.message)
    }
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const createApi = async ({
  url,
  payload,
  setLoading,
  additionalFunctions = [],
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.post(
      `${process.env.React_App_Baseurl}${url}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res) {
      if(res.data.message){
        showNotification({message: res?.data?.message, type:"success"})
      }else{
        showNotification({ message: successMessage });
      }
      additionalFunctions.forEach((func) => {
        if (typeof func === "function") {
          func();
        }
      });
      return res?.data;
    }
  } catch (e) {   
    showNotification({ message: e.response?.data?.message ? e.response?.data?.message : errorMessage, type: "danger" });
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const removeApi = async ({
  url,
  setLoading,
  additionalFunctions = [],
  showToast = true,
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.delete(`${process.env.React_App_Baseurl}${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res) {
      if (showToast)
        showNotification({ message: removeMessage });
      additionalFunctions.forEach((func) => {
        if (typeof func === "function") {
          func();
        }
      });
    }
  } catch {
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const removeApiForPdf = async ({
  url,
  setLoading,
  additionalFunctions = [],
  showToast = true,
  payload
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.post(`${process.env.React_App_Baseurl}${url}`,payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res) {
      if (showToast)
        showNotification({ message: removeMessage });
      additionalFunctions.forEach((func) => {
        if (typeof func === "function") {
          func();
        }
      });
    }
  } catch {
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const updateApi = async ({
  url,
  payload,
  setLoading,
  additionalFunctions = [],
  navigate
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.put(
      `${process.env.React_App_Baseurl}${url}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res) {
      showNotification({ message: successMessage });
      additionalFunctions.forEach((func) => {
        if (typeof func === "function") {
          func();
        }
      });
    }
  } catch {
    showNotification({ message: errorMessage, type: "danger" });
  } finally {
    if (setLoading) {
      setLoading(false);
    }
    if(navigate)
    navigate(-1)
  }
};
export const deleteApi = async ({
  url,
  additionalFunctions = [],
  setLoading,
  navigate
}) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const res = await axios.delete(
      `${process.env.React_App_Baseurl}${url}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res) {
      showNotification({ message: successMessage });
      additionalFunctions.forEach((func) => {
        if (typeof func === "function") {
          func();
        }
      });
    }
  } catch {
    showNotification({ message: errorMessage, type: "danger" });
  } finally {
    if (setLoading) {
      setLoading(false);
    }
    if(navigate)
    navigate(-1)
  }
};

export const GenerateOtp =async({ setLoading,payload })=>{

  try {
    setLoading(true)
    const res = await axios.post(
      `${process.env.React_App_Baseurl}otpChallenge`,
      payload
    );
    if (res)
    showNotification({ message: "OTP has been sent successfully to your email." });
  return res
  } catch(error) {
    if(error?.response?.data?.message){
      showNotification({ message: error?.response?.data?.message, type: "danger" });
    }else{
      showNotification({ message: 'Failed to send OTP. Please try again.', type: "danger" });
    }
  } finally {
    setLoading(false);
  }
}
// Login User
// export const LoginUser = ({ setLoading, payload, navigate }) => {
  
//   return async (dispatch) => {
//     setLoading(true);
//     try {
//       const res = await axios.post(
//         `${process.env.React_App_Baseurl}employee/otpVerifyChallenge`,
//         payload
//       );
//       const data = {
//         profile: res?.data,
//         payload,
//         timeStamp: Date.now(),
//       };
//       navigate("/dashboard");
//       dispatch(LoginSlice(data));
//       showNotification({ message: "Welcome" });
//     } catch(error) {
//       console.log("error>>API",error);
//       showNotification({ message: "Invalid OTP", type: "danger" });
//     } finally {
//       setLoading(false);
//     }
//   };
// };
export const LoginUser = ({ payload }) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.React_App_Baseurl}otpVerifyChallenge`,
      payload
    );
    const data = {
      profile: res?.data,
      payload,
      timeStamp: Date.now(),
    };
    // await SignInFirebase({payload: data})
    dispatch(LoginSlice(data));
    return Promise.resolve(res?.data)
  } catch (error) {
    return Promise.reject()
  }
};


// Upload Document
export const uploadDocument = async ({ payload, setArr, setLoading,patitentId }) => {
  setLoading(true);
  try {
    const res = await axios.post(
      `${process.env.React_App_Baseurl}employee/createUploadDocumentOnebyoneByPatientId/${patitentId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = res?.data?.data;
    setArr((prev) => [...prev, data]);
  } catch (e) {
    showNotification({ message: "Image size is too large !", type: "danger" });
  } finally {
    setLoading(false);
  }
};

export const LogOutHandler = (navigate) => {
  return async (dispatch) => {
    dispatch(LOGOUT());
    auth.signOut();
    navigate("/");
    showNotification({ message: "Logged Out Successfully", type: "success" });
  };
};

// Firebase Chat
export const createFirebaseDocument = ({
  payload,
  collectionName,
  navigate,
  navigationLink,
  handleClose,
  recipientName,
}) =>  async (dispatch) => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, collectionName),
          where("recipient._id", "==", payload.recipient._id),
          where("sender._id", "==", payload.sender._id)
        )
      );
      const querySnapshotR = await getDocs(
        query(
          collection(db, collectionName),
          where("sender._id", "==", payload.recipient._id),
          where("recipient._id", "==", payload.sender._id)
        )
      );
      if (!querySnapshot.empty) {
        const docId = querySnapshot?.docs?.[0]?.id;
        dispatch(setDocumentID(docId));
      }else if(!querySnapshotR.empty){
        const docId = querySnapshotR?.docs?.[0]?.id;
        dispatch(setDocumentID(docId));
      }
       else {
        const collectionRef = collection(db, collectionName);
        const docRef = await addDoc(collectionRef, payload);
        dispatch(setDocumentID(docRef.id));
      }
      handleClose();
      if(navigate)
      navigate(navigationLink);
    } catch (error) {
      console.error("Error adding document: ", error);
    }

};
export const createFirebaseDocumentGroup = ({
  payload,
  collectionName,
  navigate,
  navigationLink,
  handleClose,
  recipientName,
}) =>  async (dispatch) => {
    try {
     if(collectionName) {
        const collectionRef = collection(db, collectionName);
        const docRef = await addDoc(collectionRef, payload);
        dispatch(setDocumentID(docRef.id));
      }
      handleClose();
      navigate(navigationLink);
    } catch (error) {
      console.error("Error adding document: ", error);
    }

};

export const deleteDocumentById = async (collectionName, documentId) => {
  const docRef = doc(db, collectionName, documentId);

  try {
    await deleteDoc(docRef);
    showNotification({ message: "Document successfully deleted" });

  } catch (error) {
    showNotification({ message: "Error deleting document: "+ error, type: "danger" });
  }
};
export const UpdateProfile = ({ setLoading, payload,url,isAdmin }) => {
  return async (dispatch) => {
    setLoading(true);
    let baseUrl = isAdmin ? process.env.React_App_BaseurlAdmin: process.env.React_App_Baseurl 
    
    try {
      const res = await axios.put(
        url?`${baseUrl}${url}`:`${baseUrl}employee/updateProfile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = res.data.data;
      dispatch(UpdateUserProfile(data));
      showNotification({ message: "Profile updated" });
    } catch (error) {
      showNotification({ message: errorMessage, type: "danger" });
    } finally {
      setLoading(false);
    }
  };
};
export function getObjectUrlFromDownloadUrl(downloadUrl) {
  try {
    if(!downloadUrl){
      return ProfileImg
    }
    const objectUrl =  encodeURI(`https://d2u4q4ytylw93t.cloudfront.net/${downloadUrl}`);
    return objectUrl;
  } catch (error) {
    console.error('Error fetching and creating object URL:', error);
    return null;
  }
}