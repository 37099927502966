/** @format */
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { auth, db } from "../firebase";
import { SetFirebaseUser, userProfile } from "../store/authSlice";
import { useDispatch } from "react-redux";

// mm/dd/yyyy
export const DateFormtter = (date) => {
  const originalDate = new Date(date);
  const timezoneOffset = originalDate?.getTimezoneOffset();
  const adjustedTime = new Date(
    originalDate?.getTime() + timezoneOffset * 60000
  );
  const year = adjustedTime?.getFullYear();
  const month = adjustedTime?.getMonth() + 1;
  const day = adjustedTime?.getDate();

  return `${month < 10 ? `0${month}` : month}/${
    day < 10 ? `0${day}` : day
  }/${year}`;
};

// mm-dd-yy
export const DateforInput = (date) => {
  if (date) {
    const formattedDate = new Date(date)?.toISOString()?.split("T")[0];
    return formattedDate;
  }
};

export const TimeForInput = (date) => {
  if (date) {
    const formattedTime = new Date(date)?.toISOString()?.split("T")[1]?.split(".")[0];
    return formattedTime;
  }
};
// print report
export const PrintThis = (handlePrint) => {
  var elements = document.getElementsByClassName("hidePrint");
  
  for (var i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }

  handlePrint();
  setTimeout(() => {
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "block";
    }
  }, 1000);
};

// Return Signature
export const signatureFormat = ({ sign, date, time, withText = true, style = {} }) => {
  return withText === true
    ? sign && (
        <p className="mb-0 mt-2 mt-lg-0 align-items-center text-lg-end" id="Print23" style={{ fontWeight: "light", fontSize: "14px", width: '100%', ...style }}>
          Digitally Sign by {sign} {date && DateFormtter(date)} {time}{" "}
        </p>
      )
    : sign && (
        <p className="mb-0 mt-2 mt-lg-0 align-items-center text-lg-end" style={{ fontWeight: "light", fontSize: "14px", width: '100%', ...style }}>
          {sign} {date && DateFormtter(date)} {time}{" "}
        </p>
      );
};

// Chat Firebase

export const SignInFirebase = async ({ payload }) => {
  // const dispatch = useDispatch();
  const { email, _id: password } = payload;
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return Promise.resolve(userCredential.user)
    })
    .catch((error) => {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/invalid-credential"
      ) {
       return createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            return Promise.resolve(userCredential.user)
          })
          .catch((error) => {
            console.error("Error creating account:", error);
          });
      } else {
        console.error("Error signing in:", error);
      }
    });
};

// Debouncing Set Query
export const debouncedSetQuery = ({ term, setQuery }) => {
  clearTimeout(debouncedSetQuery.timeoutId);
  debouncedSetQuery.timeoutId = setTimeout(() => {
    setQuery(term);
  }, 500);
};

export const fetchPaitentName = (i) => {
  if (i?.firstName || i?.lastName) {
    return `${i?.firstName} ${i?.lastName}`;
  } else {
    return i?.fullName;
  }
};

// Bold syntax
export const BoldSyntax = (text) => {
  return <span className="fw-bold"> {text} </span>;
};

// Remove Memeber from groupChat
export const removeMemberGroup = async ({ memberIdToRemove, documentId }) => {
  const docRef = doc(
    db,
    process.env.React_App_Firebase_Group_CollectionName,
    documentId
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const documentData = docSnap.data();
    const updatedMembers = documentData.members.filter(
      (member) => member._id !== memberIdToRemove
    );
    const updatedMembersId = documentData.membersId.filter(
      (id) => id !== memberIdToRemove
    );

    try {
      await updateDoc(docRef, {
        members: updatedMembers,
        membersId: updatedMembersId,
      });
    } catch (error) {
      console.error("Error removing member from document:", documentId, error);
    }
  } else {
    console.warn("No such document!");
  }
};

// Add Member in Group
export const addMemberToDocument = async ({ documentId, newMembers }) => {
  try {
    const docRef = doc(
      db,
      process.env.React_App_Firebase_Group_CollectionName,
      documentId
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const documentData = docSnap.data();

      try {
        const updatedMembers = arrayUnion(...newMembers);
        const updatedMemberIds = newMembers.map((member) => member._id);

        await updateDoc(docRef, {
          members: updatedMembers,
          membersId: arrayUnion(...updatedMemberIds),
        });

      } catch (error) {
        console.error(
          "Error adding new members to document:",
          documentId,
          error
        );
      }
    } else {
      console.warn("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

// get Random Color
export const getRandomColor = (() => {
  let previousColorIndex = -1;

  return (colors) => {
    let randomIndex = Math.floor(Math.random() * colors.length);
    while (randomIndex === previousColorIndex) {
      randomIndex = Math.floor(Math.random() * colors.length);
    }
    previousColorIndex = randomIndex;
    return colors[randomIndex];
  };
})();

// className getter
export const getClassName = (value, key) => {
  if (key === value) {
    return "active";
  } else {
    return "";
  }
};

// signature maker
export const AddSignature = ({ show, setValue, setTime, setDate }) => {
  const today = new Date();
  const stringDate = today.toISOString();
  const [time, setTime1] = useState("");

  const profileDetails = useSelector(userProfile);
  const employeeName = profileDetails && fetchPaitentName(profileDetails);
  const employeePosition = profileDetails?.position;
  const employeeSign = `${employeeName} ${
    profileDetails.userType === "Admin" ? "Admin" : employeePosition
  }`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date();
      const hours = today.getHours();
      const min = today.getMinutes();
      const seconds = today.getSeconds();
      setTime1(`${hours}:${min}:${seconds}`);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (show) {
      setValue(employeeSign);
      setTime(time);
      setDate(stringDate?.split("T")?.[0]);
    }
  }, [show, employeeSign]);
};
export const formatDate = (isoString) => {
  if(isoString) {
    const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
  }
  // return `${day}-${month}-${year}`
};



export const BaseUrl = process.env.React_App_Baseurl;
export const AdminBaseUrl = process.env.React_App_BaseurlAdmin;

export const AppAuth = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};
