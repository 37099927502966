/** @format */
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NoFound from "./Loader/NoFound";
import { ClipLoader } from "react-spinners";
import { userProfile } from "../store/authSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const InDraft = ({ link }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      In Draft
    </Tooltip>
  );
  const navigate = useNavigate()
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Link to={link}>
        <div className="in-draft-icon cursor-pointer">
          <i className="fa-regular fa-file"></i>
        </div>
      </Link>
    </OverlayTrigger>
  );
};

export const DropList = ({
  showView = true,
  showEdit,
  showDelete,
  viewLink,
  deleteLink,
  editLink,
  behavioralHealthProfessionalData,
  behavioralHealthProfessional,
  canEdit,
  canDelete
}) => {
  const ProfileDetails = useSelector(userProfile);


  const index = behavioralHealthProfessional?.indexOf(ProfileDetails?._id) || -1;
  let showEditBtn, showDeleteBtn;

  if (index === -1) {
    showEditBtn = false;
    showDeleteBtn = false;
  } else {
    showEditBtn =
      (ProfileDetails?.userType === "Admin") ||
      (showEdit === true && behavioralHealthProfessionalData?.[index]?.signature === '') ||
      (showEdit === true && ProfileDetails?.accountType === "adminstrator") ||
      (ProfileDetails?.accountType === "regular" && ProfileDetails?.userType === "Employee" && ProfileDetails.permissionEditDocuments === true)
  }
  showDeleteBtn = ProfileDetails.userType === 'Admin' || ProfileDetails?.accountType === "adminstrator" || ( ProfileDetails?.accountType === "regular" && ProfileDetails?.userType === "Employee" && canDelete);
  if(canEdit) {
    // if( ProfileDetails?.accountType === "adminstrator" ||  ( ProfileDetails?.accountType === "regular" && ProfileDetails?.userType === "Employee" && ProfileDetails.permissionEditDocuments === true)  ){
    showEditBtn = true
    // }
  }

  if (ProfileDetails?.userType === "Admin") showEditBtn = true;

  return (
    <div className="icon-joiner">
      {showView && (
        <Link className="view-btn" to={viewLink}>
          <i className="fa-solid fa-eye" />
        </Link>
      )}
      {showEditBtn && (
        <Link className="edit-btn" to={editLink}>
          <i className="fa-solid fa-edit" />
        </Link>
      )}
      {showDeleteBtn && (
        <Link className="del-btn">
          <i className="fa-solid fa-trash-can" onClick={() => deleteLink()} />
        </Link>
      )}
    </div>
  );
};

export const Loader = () => {
  return (
    <div className="spinner">
      <ClipLoader color="rgb(26, 159, 178)" />
    </div>
  );
};

export const TableLayout = ({ thead, tbody, loading }) => {
  return loading ? (
    <Loader />
  ) : tbody?.length > 0 ? (
    <Table responsive bordered className="table-cell-ellipsis">
      <thead>
        <tr>
          {thead?.map((i, index) => (
            <th key={`thead${index}`}> {i} </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody?.map((rowData, rowIndex) => (
          <tr key={`row${rowIndex}`}>
            {rowData?.map((cellData, cellIndex) => (
              <td key={`cell${cellIndex}`}>{cellData}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <NoFound />
  );
};
