/** @format */

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HOC from "../../../Layout/Inner/HOC";
import CreateNav from "../../../Helper/CreateNav";
import { getApi, removeApi } from "../../../Repository/Apis";
import { DateFormtter, formatDate } from "../../../utils/utils";
import {
  InDraft,
  DropList,
  TableLayout,
} from "../../../components/HelpingComponents";
import { useSelector } from "react-redux";
import { userProfile } from "../../../store/authSlice";

const ProgressNote = () => {
  const profileUser = useSelector(userProfile);
//   const [draftData, setDraftData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: profileUser.userType==="Patient" ?   `Patient/InitialAssessment/${profileUser?._id}`: "initial-assessments" ,
      setResponse: setData,
      setLoading,
    });
    // getApi({
    //   url: "employee/getAllProgressNoteSaveAsDraft",
    //   setResponse: setDraftData,
    // });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const deleteProgressNoteHandler = (id) => {
    const additionalFunctions = [fetchHandler];
    removeApi({
      url: `delete-initial-assessment/${id}`,
      successMsg: "Removed !",
      additionalFunctions,
    });
  };

  const currentUserId = useSelector(userProfile)._id;

  const dataList =
    data?.data?.length > 0
      ? [...data?.data]
          ?.reverse?.()
          ?.map((data) => [
            data?.residentName,
            formatDate(data?.todayDate),
            data?.patientId?.diagnosis,
            <DropList
              viewLink={profileUser.userType==="Patient" ?  `/view-initial-assessment-resident/${data?._id}`:`/view-initial-assessment/${data?._id}`}
              editLink={ profileUser.userType==="Patient" ?  `/edit-initial-Assessment-resident/${data?._id}`:`/edit-initial-assessment/${data?._id}`}
              deleteLink={() => deleteProgressNoteHandler(data?._id)}
              canEdit={(data?.signers?.filter?.(
                (signer) =>
                  signer.signerId === currentUserId &&
                  !signer?.signature?.length
              )?.length)  || profileUser?.userType==="Admin"|| profileUser?.accountType === "adminstrator" || ( profileUser?.accountType === "regular" && profileUser?.userType === "Employee" && profileUser.userPermissions?.edit?.split(":").includes("iass"))}
              canDelete={profileUser?.userPermissions?.delete?.split(":").includes("iass")}
              />,
          ])
      : [];



  const combinedDataSource = [...dataList];

  return (
    <>
      <CreateNav title={"Initial Assessment"} link={"/initial-assessment"}  isAuthorizedToCreate={ profileUser.userType!=="Patient"} />

      <Container className="full-width-container">
        <TableLayout
          thead={["Resident’s Name", "Admit Date", "Diagnosis", "Action"]}
          tbody={combinedDataSource}
          loading={loading}
        />
      </Container>
    </>
  );
};

export default HOC(ProgressNote);
