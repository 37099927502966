import { useState, useEffect, useRef } from "react";

const CustomSelect = ({ options, onChange, styleOpt,value}) => {
  const [inputValue, setInputValue] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options||[]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(()=>{
    setFilteredOptions(options)
  },[options])
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // setFilteredOptions(
    //   options?.filter((option) =>
    //     option?.toLowerCase()?.includes(value.toLowerCase())
    //   )
    // );
    setIsOpen(true);
    onChange(value);
  };
  useEffect(()=>{
    if(value && !inputValue){
      setDefaultValue(Array.isArray(value)?value?.join(','):value)
    }
  },[value])
  const handleOptionClick = (option) => {
    setInputValue(option?.label||option);
    setIsOpen(false);
    onChange(option?.value||option);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select-wrapper" ref={wrapperRef} >
      <input
        type="text"
        value={inputValue||defaultValue}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder="Type to search or select"
        className="custom-select-input"
        required
        style={styleOpt}
        
      />
      {isOpen && (
        <div className="custom-options-container">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="custom-option"
                onClick={() => handleOptionClick(option)}
              >
                {option?.label||option}
              </div>
            ))
          ) : (
            <div className="custom-no-options">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
