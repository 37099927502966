/** @format */

import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar";
import "../../CSS/Sidebar.css";

const HOC = ({ Wcomponenet, isNavbar = true }) => {
  return function Component() {
    const [hamb, setHamb] = useState(false);
    return (
      <>
        <section className="flex mh-100vh">
          {/* Sidebar */}
          <div
            className={
              hamb
                ? " absolute top-0 z-30 md:w-auto shadow-md sm:bg-[#1A9FB2] w-60 transition-all md:-left-full left duration-150 left-0 "
                : " bg-[#1A9FB2] shadow-md md:static absolute top-0 -left-full transition-all duration-150  asidebar"
            }
          >
            <Sidebar hamb={hamb} setHamb={setHamb} />
          </div>
          {/* Components & Navbar */}
          <div
            className={
              hamb
                ? "w-full transition-all py-2 duration-150"
                : "w-full transition-all py-2 duration-150 z-50 right-content"
            }
          >
            {isNavbar && <Navbar hamb={hamb} setHamb={setHamb} />}
            <div className="content-wrappper wcomp">
              <Wcomponenet />
            </div>
          </div>
        </section>
      </>
    );
  };
};

export default HOC;
